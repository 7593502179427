import React from "react";
import {
  Container,
  ContentWithPaddingXl,
  ContentWithPaddingMd,
} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";
import Images from "Images";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";
const HeadingContainer = tw.div`text-center `;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`leading-none mx-4`;
const Description = tw(SectionDescription)`mx-auto`;

const Posts = tw.div`mt-12 flex flex-wrap -mr-3 relative`;

const Post = tw.a`flex flex-col h-screen sm:h-full bg-gray-200 rounded`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const PostImage = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-128 sm:h-80 bg-center bg-cover rounded-t`}
`;
const PostText = tw.div`flex-1 px-6 py-8`;

const PostTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `;
const PostDescription = tw.p``;
const AuthorInfo = tw.div`flex`;

const AuthorName = tw.div`font-semibold mt-2`;

const PlanFeatures = styled.ul`
  ${tw` lg:-mx-6 -mx-6 sm:-mx-10  px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-primary-900 tracking-wide ml-3`}
    }
  }
`;
const LeftHeading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl  text-center md:text-left leading-none `;
const PostContainer = styled.div`
  ${tw`relative z-20 mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full sm:w-full h-128 mx-8`}
      ${Post} {
        ${tw`sm:flex-row items-center sm:pr-3`}
      }
      ${PostImage} {
        ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l`}
      }
      ${PostText} {
        ${tw`pl-8 pr-5`}
      }
      ${PostTitle} {
        ${tw`text-2xl`}
      }
      ${PostDescription} {
        ${tw`mt-4 text-sm font-semibold text-gray-600 leading-relaxed`}
      }
      ${AuthorInfo} {
        ${tw`mt-8 flex items-center`}
      }
      ${AuthorName} {
        ${tw`mt-0 font-bold text-gray-700 text-sm`}
      }
    `}
`;

const DecoratorBlob1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`;

export default ({
  subheading = "",
  heading = "See how Beekman 1802 used SMS to grow their subscriber list",
  heading1 = "Michaels drives engagement and",
  heading2 = " conversions with multi-touch,",
  heading3 = " multi-channel messages with magic",
  heading4 = "composer",

  description = "",
  posts = [
    {
      postImageSrc:
        "https://images.unsplash.com/photo-1563784462041-5f97ac9523dd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
      authorImageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.95&w=512&h=512&q=80",
      title: "BEEKMAN",
      description:
        "“The ease of use of Attentive’s sign-up units has been huge for us. We’ve seen massive success to date capturing SMS and email at the same time. That one pop-up has led to the growth of both of our lists. It’s completely hands-off.”",
      authorName: "Adam Bshero",
      authorProfile: "VP of Digital Marketing",
      url: "https://google.com",
      leftheading:
        "Choose from 30+ sign-up units to collect opt-ins across all your marketing channels",
      featured: true,
      features: [
        "landing pages",
        "email",
        "social media",
        "e-commerce checkout",
        "in-store",
      ],
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <Posts>
          {posts.map((post, index) => (
            <PostContainer featured={post.featured} key={index}>
              <Post className="group">
                <PostText>
                  <LeftHeading>{post.leftheading}</LeftHeading>
                </PostText>

                <PostText>
                  <PlanFeatures>
                    {post.features.map((feature, index) => (
                      <li className="feature" key={index}>
                        <CheckboxIcon className="icon" />
                        <span className="text">{feature}</span>
                      </li>
                    ))}
                  </PlanFeatures>
                </PostText>
              </Post>
            </PostContainer>
          ))}
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </Posts>
      </ContentWithPaddingXl>
    </Container>
  );
};
