import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

import { Subheading as SubheadingBase } from "components/misc/Headings.js";

import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import Images from "Images";

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-2xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:mt-16 relative `;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");
  background-size: cover; /* Ensure the image covers the entire container */
  height: 100%;` /* Make the image take up full height */,

  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

// const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block  mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-primary-700`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto rounded-full md:mx-0`;

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);
const HeaderRow = tw.div`flex justify-center items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex justify-center flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-2 `;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(
  motion.div
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(
  motion.a
)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
const Statistics = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
`;

const StatisticPair = styled.div`
  display: flex;
  flex-direction: column;
`;
const StatisticsContainer = styled.div`
  max-width: 600px; /* Adjust the maximum width as needed */
`;
export default ({
  heading = "Checkout the Menu",
  textOnLeft = true,
  tabs = {
    CLAIRS: [
      {
        title: "How Claire’s Turned SMS Into a Top Global Revenue Channel",
        imageSrc: Images.Clairs,
        key: "total US SMS program ROI",
        value: "17X+",
        key1: "increase in US SMS subscribers",
        value1: "149%",
        key2: "average CTR for all triggered messages",
        value2: "20%",
      },
    ],
    GUESS: [
      {
        title:
          "How GUESS Got Creative With SMS to Reach and Engage Canadian Shoppers",
        imageSrc: Images.Guess,
        key: "in attributed revenue across US and Canadian SMS programs",
        value: "$12M+",
        key1: "total SMS Program ROI in Canada",
        value1: "30X",
        key2: "CTR of Two-Way Journeys in Canada",
        value2: "19%",
      },
    ],
    APL: [
      {
        title:
          "How APL Delivers Luxury Experiences and Drives Repeat Purchases via SMS",
        imageSrc: Images.Apl,
        key: "ROI",
        value: "78X+",
        key1: "average CVR of triggered text messages",
        value1: "32%",
        key2: "of SMS subscribers have made 3+ purchases",
        value2: "25%",
      },
    ],
    RESERVEBAR: [
      {
        title:
          "How ReserveBar Uses Attentive to Increase Both Engagement and AOV",
        imageSrc: Images.Bar,
        key: "SMS-Attributable Revenue",
        value: "$4.5M",
        key1: "Total SMS Program ROI",
        value1: "25X",
        key2: "Higher AOV from SMS subscribers than subscribers acquired through paid social",
        value2: "16%",
      },
    ],
  },
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          {/* <Header>{heading}</Header> */}
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl
                key={index}
                active={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
              >
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <TwoColumn css={tw`md:items-center`}>
                <TextColumn>
                  <TextContent>
                    {/* {subheading && <Subheading>{subheading}</Subheading>} */}
                    <Heading>{card.title}</Heading>
                    {/* <Description>dfghjkl;kjhg</Description> */}
                    <StatisticsContainer>
                      <Statistics>
                        {/* {tabs[tabKey].map((statistic, index) => ( */}
                        {/* <Statistic key={index}> */}
                        <StatisticPair>
                          <Value>{card.value}</Value>
                          <Key>{card.key}</Key>
                        </StatisticPair>
                        <StatisticPair>
                          {" "}
                          <Value>{card.value1}</Value>
                          <Key>{card.key1}</Key>
                        </StatisticPair>
                        <StatisticPair>
                          {" "}
                          <Value>{card.value2}</Value>
                          <Key>{card.key2}</Key>
                        </StatisticPair>
                        {/* </Statistic> */}
                        {/* ))} */}
                      </Statistics>
                    </StatisticsContainer>
                    <PrimaryButton as="a" href="/case-study/nestle">
                      Read Now
                    </PrimaryButton>
                  </TextContent>
                </TextColumn>
                <ImageColumn>
                  <Image imageSrc={card.imageSrc} alt="" />
                </ImageColumn>
              </TwoColumn>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

// import React from "react";
// import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import {
//   SectionHeading,
//   Subheading as SubheadingBase,
// } from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import StatsIllustrationSrc from "images/stats-illustration.svg";
// import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

// const Container = tw.div`relative`;
// const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
// const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
// const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:mt-16 relative`;
// const TextColumn = styled(Column)((props) => [
//   tw`md:w-7/12 mt-16 md:mt-0`,
//   props.textOnLeft
//     ? tw`md:mr-12 lg:mr-16 md:order-first`
//     : tw`md:ml-12 lg:ml-16 md:order-last`,
// ]);

// const Image = styled.div((props) => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded bg-contain bg-no-repeat bg-center h-full`,
// ]);
// const TextContent = tw.div`lg:py-8 text-center md:text-left`;

// const Subheading = tw(SubheadingBase)`text-center md:text-left`;
// const Heading = tw(
//   SectionHeading
// )`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
// const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

// const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
// const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
// const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
// const Key = tw.div`font-medium text-primary-700`;

// const PrimaryButton = tw(
//   PrimaryButtonBase
// )`mt-8 md:mt-10 text-sm inline-block mx-auto rounded-full md:mx-0`;

// const DecoratorBlob = styled(SvgDotPattern)((props) => [
//   tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
// ]);

// export default ({
//   subheading = "Get Started Today",
//   heading = (
//     <>
//       We have been doing this <wbr /> since{" "}
//       <span tw="text-primary-500">2020.</span>
//     </>
//   ),
//   description = "Whether you're a small business or a large enterprise, TXT Connect is here to transform your communication strategy in Ghana. Sign up today to experience the power of seamless, effective messaging tailored for the Ghanaian audience.",
//   primaryButtonText = "Get Started",
//   primaryButtonUrl = "/sign-up",
//   imageSrc = StatsIllustrationSrc,
//   imageCss = null,
//   imageContainerCss = null,
//   imageDecoratorBlob = false,
//   imageDecoratorBlobCss = null,
//   imageInsideDiv = true,
//   statistics = null,
//   textOnLeft = false,
// }) => {
//   // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
//   //Change the statistics variable as you like, add or delete objects
//   const defaultStatistics = [
//     {
//       key: "Clients",
//       value: "2282+",
//     },
//     {
//       key: "Projects",
//       value: "3891+",
//     },
//     {
//       key: "Awards",
//       value: "1000+",
//     },
//   ];

//   if (!statistics) statistics = defaultStatistics;

//   return (
//     <Container>
//       <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
//         <ImageColumn css={imageContainerCss}>
//           {imageInsideDiv ? (
//             <Image imageSrc={imageSrc} css={imageCss} />
//           ) : (
//             <img src={imageSrc} css={imageCss} alt="" />
//           )}
//           {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
//         </ImageColumn>
//         <TextColumn textOnLeft={textOnLeft}>
//           <TextContent>
//             {subheading && <Subheading>{subheading}</Subheading>}
//             <Heading>{heading}</Heading>
//             <Description>{description}</Description>

//             <PrimaryButton as="a" href={primaryButtonUrl}>
//               {primaryButtonText}
//             </PrimaryButton>
//           </TextContent>
//         </TextColumn>
//       </TwoColumn>
//     </Container>
//   );
// };
