import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { RiInformationFill } from "react-icons/ri";
{
  /* <Link href="/products/sms-broadcast">Products</Link> */
}

const IconWrapper = tw.div`absolute lg:ml-[22rem] hidden lg:block  text-gray-500 cursor-pointer`;

const Tooltip = tw.span`absolute w-96 top-0 right-0 p-2 mt-8 text-xs text-white bg-black rounded opacity-0 transition-opacity duration-300 pointer-events-none`;

const RevenueCalculator = ({ formAction = "#", formMethod = "get" }) => {
  const handleShowTooltip = () => {
    const tooltip = document.getElementById("tooltip");
    if (tooltip) {
      tooltip.style.opacity = "1";
    }
  };

  const handleHideTooltip = () => {
    const tooltip = document.getElementById("tooltip");
    if (tooltip) {
      tooltip.style.opacity = "0";
    }
  };
  const handleCopyLink = () => {
    const currentURL = window.location.href;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(currentURL)
        .then(() => {
          alert("Page URL copied to clipboard: " + currentURL);
        })
        .catch((error) => {
          console.error("Unable to copy page URL: ", error);
        });
    } else {
      // Fallback for browsers that don't support clipboard API
      fallbackCopyTextToClipboard(currentURL);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Hide the textarea from rendering
    textArea.style.position = "fixed";
    textArea.style.opacity = 0;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      // alert("Page URL copied to clipboard: " + text);
    } catch (err) {
      console.error("Unable to copy page URL: ", err);
    }

    document.body.removeChild(textArea);
  };

  //
  const [formData, setFormData] = useState({
    // monthlyTraffic: "",
    averageOrderValue: "",
    smsList: "",
    conversionRate: "",
    totalLeads: "",
    totalRevenue: "",
  });
  useEffect(() => {
    // const calculateConversionRate = () => {
    //   const { monthlyTraffic, smsList } = formData;
    //   const conversionRate = (
    //     (parseInt(monthlyTraffic) + parseInt(smsList)) *
    //     0.03
    //   ).toFixed(2);
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     conversionRate,
    //   }));
    // };

    const calculateTotalLeads = () => {
      const { smsList, conversionRate } = formData;
      const totalLeads =
        smsList && conversionRate
          ? (parseInt(smsList) * (parseInt(conversionRate) / 100)).toFixed(2)
          : 0;
      setFormData((prevData) => ({
        ...prevData,
        totalLeads,
      }));
    };

    const calculateTotalRevenue = () => {
      const { smsList, conversionRate } = formData;
      const totalRevenue =
        smsList && conversionRate
          ? (
              parseInt(smsList) *
              (parseInt(conversionRate) / 100) *
              0.03
            ).toFixed(2)
          : 0;
      setFormData((prevData) => ({
        ...prevData,
        totalRevenue,
      }));
    };

    // calculateConversionRate();
    calculateTotalLeads();
    calculateTotalRevenue();
  }, [formData.smsList, formData.averageOrderValue, formData.conversionRate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //

  // const [formData, setFormData] = useState({
  //   monthlyTraffic: "",
  //   averageOrderValue: "",
  //   smsList: "",
  //   conversionRate: "",
  // });

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleReset = () => {
    // Reset the form data
    setFormData({
      // monthlyTraffic: "",
      averageOrderValue: "",
      smsList: "",
      conversionRate: "",
    });

    // Reset the input fields using DOM manipulation
    // document.getElementById("monthlyTraffic").value = "";
    document.getElementById("averageOrderValue").value = "";
    document.getElementById("smsList").value = "";
    document.getElementById("conversionRate").value = "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with values:", formData);
  };
  return (
    <TwoColumn>
      <FormContent>
        <Form action={formAction} method={formMethod} onSubmit={handleSubmit}>
          {/* <FormContainer>
            <Label htmlFor="monthlyTraffic">
              Total Monthly Website/Social media Traffic
              <SubLabel>Online audience reach, monthly</SubLabel>
            </Label>
            <Input
              type="number"
              name="monthlyTraffic"
              placeholder="10,000"
              id="monthlyTraffic"
              value={formData.monthlyTraffic}
              onChange={handleInputChange}
            />
          </FormContainer> */}

          <FormContainer>
            <Label htmlFor="smsList">
              SMS, VOICE, EMAIL List{" "}
              <SubLabel>How many target audience to reach</SubLabel>
            </Label>
            <Input
              type="number"
              name="smsList"
              id="smsList"
              placeholder="30,000"
              value={formData.smsList}
              onChange={handleInputChange}
            />
          </FormContainer>
          <FormContainer>
            <Label htmlFor="conversionRate">
              Conversion Rate (%)
              <SubLabel>
                The number of target audience who will interact with your Ad
              </SubLabel>
            </Label>
            <Input
              type="number"
              name="conversionRate"
              id="conversionRate"
              placeholder="1200"
              value={formData.conversionRate}
              // readOnly
              onChange={handleInputChange}
            />
          </FormContainer>
          <FormContainer>
            <Label htmlFor="averageOrderValue">
              Average monthly Sales Value{" "}
              <SubLabel>How much revenue you generate averagely</SubLabel>
            </Label>

            <Input
              type="number"
              name="averageOrderValue"
              id="averageOrderValue"
              placeholder="GH₵ 2,000"
              value={formData.averageOrderValue}
              onChange={handleInputChange}
            />
          </FormContainer>
          <ResetBtn>
            <SubmitButton type="button" onClick={handleReset}>
              Reset Inputs
            </SubmitButton>
          </ResetBtn>
        </Form>
      </FormContent>

      {/* <div className="p-4 bg-white rounded-lg shadow-md"> */}
      <CountColumn>
        <IconWrapper
          onMouseEnter={handleShowTooltip}
          onMouseLeave={handleHideTooltip}
        >
          <RiInformationFill size={20} />
          <Tooltip id="tooltip">
            Leads estimate assumes the full transfer of existing SMS list, if
            applicable, and attributes an intercept value and multiplier based
            on monthly visitors. The intercept value and multiplier are
            assumptions based on historical data of TXTConnect customers in
            converting traffic to subscribers.
            <br /> <br />
            Revenue estimate is based on the subscriber estimate, conversion
            rate and ends per subscriber. Conversion rate and sends per
            subscriber assumptions are based on the average order value and
            traffic input into the Calculator compared to average historical
            data of TXTConnect customers.
          </Tooltip>
        </IconWrapper>

        <Title>TXTConnect </Title>

        <TwoCountColumn>
          <SubscribeColumn>
            <Heading>Leads</Heading>
            <SubHeading>Total Number of Leads</SubHeading>
            <InputData value={formData.totalLeads} readOnly placeholder="0" />
          </SubscribeColumn>
          <RevenueColumn>
            <Heading>Revenue</Heading>
            <SubHeading>Projected conversions</SubHeading>
            <InputData value={formData.totalRevenue} readOnly placeholder="0" />
          </RevenueColumn>
        </TwoCountColumn>
        <BtnWrapper>
          <CopyBtn as="a" href="/login">
            Get Started
          </CopyBtn>
        </BtnWrapper>
      </CountColumn>
    </TwoColumn>
  );
};
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TwoColumn = tw.div`flex flex-col lg:flex-row justify-between max-w-screen-lg py-10 md:py-12`;
const TwoCountColumn = tw.div`flex flex-col lg:flex-row justify-between  mx-auto py-10 md:py-12`;
const SubscribeColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0  px-4   `,
]);
const RevenueColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0  px-4  `,
]);
const Heading = tw.h1`font-bold text-lg   text-black`;
const SubHeading = tw.h1`font-medium text-sm  text-black md:h-20`;
const Label = tw.label`block text-sm  lg:mx-auto lg:text-lg mt-2 lg:w-96 font-semibold text-black`;
const SubLabel = tw.label`block text-sm  lg:mx-auto lg:w-96  text-gray-500`;
const Title = tw.h1`font-bold text-xl px-4  text-black`;
const CopyBtn = tw.button`px-8 py-2 rounded-full text-primary-500 hover:text-white bg-transparent hover:bg-primary-500 border border-primary-500`;
const BtnWrapper = tw.div`px-4 mt-8`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const FormContent = styled(Column)((props) => [
  tw`lg:w-7/12 mt-16 lg:mt-0 bg-white border-t border-b border-l shadow-md px-4 pb-10 pt-0 `,
]);
const CountColumn = styled(Column)((props) => [
  tw`lg:w-5/12  lg:mt-0 bg-gray-100 border-t border-b border-r shadow-md px-4 py-10`,
]);
const FormContainer = tw.form`mt-10 text-sm flex flex-col lg:flex-row`;
const Form = tw.form``;
const Input = tw.input`border text-black w-full mt-2 lg:mt-0 lg:w-1/4 h-10 pl-2 mx-auto rounded focus:outline-none font-medium transition duration-300 hocus:border-black flex `;
const InputData = tw.input`mt-4 w-10/12 text-black border-b  bg-gray-100  pl-2 rounded focus:outline-none font-medium transition duration-300 hocus:border-black flex `;
const SubmitButton = tw.button`inline-block rounded-full   px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
const ResetBtn = tw.div`flex justify-center mt-10 `;

export default RevenueCalculator;
