import React from "react";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
const Container = tw.div`flex justify-center flex-col md:flex-row mx-auto sm:px-8 max-w-screen-xl pb-20 md:pb-24 lg:space-x-8`;

const FirstSection = tw.div`md:w-4/12 p-2 md:p-4 text-left order-last md:order-first`;
const SecondSection = tw.div`md:w-8/12 p-2 md:p-4 order-first md:order-last `;
const Heading = tw.h1`font-bold text-xl md:text-2xl lg:text-2xl xl:text-2xl text-gray-900 my-2 lg:my-4  leading-tight`;
const Subheading = tw(SubheadingBase)`text-center md:text-left md:mb-8 mb-4 `;
const Paragraph = tw.p`mb-2 lg:mb-4 text-base  text-gray-700`;
const ComHeading = tw.h1`font-semibold text-lg md:text-xl lg:text-xl xl:text-xl text-gray-900 my-2 lg:my-1 leading-tight`;

const ComParagraph = tw.p`mb-2 lg:mb-4 text-sm  text-gray-700`;
const List = tw.li` text-base  text-gray-700`;
const CompanyDetails = () => {
  return (
    <Container>
      <FirstSection>
        {/* Company Details */}
        <Heading>Nestle</Heading>
        <Paragraph>
          Nestlé Ghana Limited started business in Ghana in 1957 under the
          trading name of Nestlé Products (Gh) Limited with the importation of
          Nestlé products such as milk and chocolates. In 1968, it was
          incorporated as Food Specialties (Gh) Limited to manufacture and
          market locally well known Nestlé brands.
        </Paragraph>
        <ComHeading>Featuring</ComHeading>
        <ComParagraph>
          Georgios Badaro
          <br />
          Director, CRM & Loyalty
        </ComParagraph>
        <ComHeading>Industry</ComHeading>
        <ComParagraph>Food</ComParagraph>
        <ComHeading>With TXTConnect Since</ComHeading>
        <ComParagraph>2020</ComParagraph>

        {/* Add more details about the company */}
      </FirstSection>
      <SecondSection>
        <Heading>Introduction</Heading>
        <Paragraph>
          Nestle IDEAL Trip to Dubai Promo 2023 was a marketing campaign
          designed to promote Nestle IDEAL milk and create excitement among
          consumers. The campaign used TXTConnect's bulk messaging solutions to
          send SMS messages to consumers, announcing the promotion and
          encouraging them to participate.
        </Paragraph>
        <Heading>Challenge or Problem Statement</Heading>
        <Paragraph>
          Nestle IDEAL was facing a challenge in recruiting new and lapsed
          consumers and regaining market share. The company wanted to develop a
          bold and impactful promotion that would create sustained excitement
          among consumers and shoppers.
        </Paragraph>
        <Heading>Objectives</Heading>
        <Paragraph>
          The objectives of the Nestle IDEAL Trip to Dubai Promo 2023 were to:
        </Paragraph>

        <List>Increase brand awareness and engagement</List>
        <List>Drive an increase in weight of purchases and consumption</List>
        <List>Recruit new and lapsed consumers</List>
        <List>Regain market share</List>

        <Heading>Our Solutions</Heading>
        <Paragraph>
          TXTConnect provided the following solutions for the Nestle IDEAL Trip
          to Dubai Promo 2023:
        </Paragraph>

        <List>Developed a USSD application for the promotion</List>
        <List>
          Developed an online dashboard and analytics arm for monitoring all
          engagement on the USSD in real time
        </List>

        <List>
          Developed the randomization engine for the weekly and grand draws
        </List>
        <List>
          {" "}
          Contacted weekly winners and grand draw winners for verification and
          processing
        </List>
        <List>Automated the disbursal of MoMo to weekly draw winners</List>
        <List>
          Kept the NLA informed on weekly entries and winnings throughout the
          promotion period
        </List>

        <Heading>Results</Heading>
        <Paragraph>
          The Nestle IDEAL Trip to Dubai Promo 2023 was a resounding success.
          The campaign achieved the following results:
        </Paragraph>

        <List>
          The IDEAL trip to Dubai promo received the highest entries ever
          recorded as far as campaigns administered via USSD are concerned.
        </List>
        <List>
          USSD application, dashboard, and draw interfaces were very well
          developed and functioned as they should.
        </List>

        <List>
          Representatives from the NLA were always present for all weekly and
          grand draws.
        </List>
        <List>
          Nestle officials were also present in their numbers on weekly draw
          days and on the grand draw day.
        </List>
        <List>
          Nestle officials were also present in their numbers on weekly draw
          days and on the grand draw day.
        </List>
        <List>
          All entries and winners during the promotion were verified and
          approved before disbursement.
        </List>
        <List>
          This promotion had the highest MoMo reward redemptions of about 95%
          successful rate.
        </List>
        <List>
          Almost all complaints were resolved and handled professionally.
        </List>
        <List>
          Some winners shared testimonials, thanking Nestle IDEAL for rewarding
          them and telling other consumers and potential consumers are real the
          promotion was.
        </List>
        <Heading>Conclusions</Heading>
        <Paragraph>
          The Nestle IDEAL Trip to Dubai Promo 2023 is a testament to the power
          of TXTConnect's bulk messaging solutions. TXTConnect's solutions
          helped Nestle IDEAL achieve its objectives and create a successful
          marketing campaign. I hope this case study is helpful. Please let me
          know if you have any other questions.
        </Paragraph>
      </SecondSection>
    </Container>
  );
};

export default CompanyDetails;
