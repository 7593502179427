import React from "react";
import {
  Container,
  ContentWithPaddingXl,
  ContentWithPaddingMd,
} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";
import Images from "Images";
const HeadingContainer = tw.div`text-center mb-12`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`leading-none mx-4`;
const Description = tw(SectionDescription)`mx-auto`;

const Posts = tw.div` flex flex-wrap -mr-3 relative`;

const Post = tw.a`flex flex-col h-full bg-gray-200 rounded`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const PostImage = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-128 sm:h-80 bg-center bg-contain  bg-no-repeat rounded-t`}
`;
const PostText = tw.div`flex-1 px-6 py-8`;

const PostTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `;

const PostDescription = tw.p``;
const AuthorInfo = tw.div`flex`;

const AuthorTextInfo = tw.div`text-xs text-gray-600`;
const AuthorName = tw.div`font-semibold mt-2`;
const AuthorProfile = tw.div`pt-1 font-medium`;

const PostContainer = styled.div`
  ${tw`relative z-20  sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full sm:w-full h-144 mx-8`}
      ${Post} {
        ${tw`sm:flex-row items-center sm:pr-3`}
      }
      ${PostImage} {
        ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l`}
      }
      ${PostText} {
        ${tw`pl-8 pr-5 `}
      }
      ${PostTitle} {
        ${tw`text-2xl`}
      }
      ${PostDescription} {
        ${tw`mt-4 text-sm font-semibold text-gray-600 leading-relaxed`}
      }
      ${AuthorInfo} {
        ${tw`mt-8 flex items-center`}
      }
      ${AuthorName} {
        ${tw`mt-0 font-bold text-gray-700 text-sm`}
      }
    `}
`;

const DecoratorBlob1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`;

export default ({
  subheading = "AUDIENCE MANAGER",

  heading1 = "Michaels drives engagement and",
  heading2 = " conversions with multi-touch,",
  heading3 = " multi-channel messages with magic",
  heading4 = "composer",
  heading = "Results from our customers",
  description = "",
  posts = [
    {
      postImageSrc:
        "https://images.unsplash.com/photo-1563784462041-5f97ac9523dd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
      authorImageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.95&w=512&h=512&q=80",
      title: "mented",
      subtitle: "Strategic guidance from launch to optimization",
      description1:
        "“We've seen tremendous growth within our SMS program. Our audience loves to interact with our brand via text messaging.”",
      authorName: "Rick Egan",
      authorProfile: "Chief Marketing Officer",
      url: "https://reddit.com",
      featured: true,
      title1: "Innovative Platform",
      subtitle1: "Advanced automation for campaigns and journeys",
      description:
        "“Make-up can be really hard to buy online, so we have to do everything we can to make purchasing as easy as possible. Our text messaging program with Attentive allows us to segment messages to do just that.”",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <ContentWithPaddingMd>
          <HeadingContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            {heading && <Heading>{heading}</Heading>}

            {description && <Description>{description}</Description>}
          </HeadingContainer>
        </ContentWithPaddingMd>

        <Posts>
          {posts.map((post, index) => (
            <PostContainer featured={post.featured} key={index}>
              <Post className="group">
                <PostImage imageSrc={Images.Audience5} />

                <PostText>
                  <PostTitle>{post.title}</PostTitle>

                  {post.featured && (
                    <PostDescription>{post.description}</PostDescription>
                  )}
                  <AuthorInfo>
                    <AuthorTextInfo>
                      <AuthorName>{post.authorName}</AuthorName>
                      {post.featured && (
                        <AuthorProfile>{post.authorProfile}</AuthorProfile>
                      )}
                    </AuthorTextInfo>
                  </AuthorInfo>
                </PostText>
              </Post>
            </PostContainer>
          ))}
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </Posts>
      </ContentWithPaddingXl>
    </Container>
  );
};
