import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
  SectionAnotherHeading,
} from "components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";
import styled from "styled-components";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
const Container = tw(ContainerBase)`bg-primary-100 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(
  SectionHeading
)`sm:text-3xl md:text-4xl lg:text-5xl text-left leading-none`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-left`;
const Description = tw(
  SectionDescription
)`text-gray-400 text-center mx-auto max-w-screen-md`;
const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md mx-auto`;
const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`items-center justify-center mt-8 text-sm inline-block  bg-white text-red-500`,
  props.buttonRounded && tw`rounded-full`,
]);

export default ({
  subheading = "TEXTS WE LOVE",
  heading = "Whether you're a small business or a large enterprise, TXTConnect is here to transform your communication strategy in Ghana. Sign up today to experience the power of seamless, effective messaging tailored for the Ghanaian audience.",
  description = "",
  primaryButtonUrl = "/sign-up",
  buttonRounded = true,
  stats = [
    {
      key: "Higher conversion rates",
      value: "2x+",
    },
    {
      key: "Higher spend",
      value: "50%",
    },
    {
      key: "Messages delievered p/m",
      value: "150+",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <PrimaryButton
          buttonRounded={buttonRounded}
          as="a"
          href={primaryButtonUrl}
        >
          {/* {primaryButtonText} */}
          Get Started
        </PrimaryButton>
        {/* <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer> */}
      </ContentWithPaddingXl>
    </Container>
  );
};
