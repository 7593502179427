import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import Images from "Images";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-2xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-lg text-gray-600 text-center max-w-2xl`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-5/12 flex-shrink-0 h-80 md:h-96  bg-no-repeat bg-contain bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-12  lg:my-0`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-xl mx-4 sm:mx-8 md:mx-4 lg:mx-12`;

const Title = tw.h4`text-2xl sm:text-2xl lg:text-3xl font-bold text-gray-900 leading-tight`;
const Description = tw.p`mt-2 text-base font-medium `;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: Images.Client1,

      subtitle: "Paid",
      title: "Comprehensive Compatibility",
      description:
        "Our bulk messaging integration is designed for comprehensive compatibility with a variety of CRMs and e-commerce platforms, ensuring versatility and adaptability for your business ecosystem.",
      url: "/contact",
      learn: "Learn About Audience Manager",
    },

    {
      imageSrc: Images.Client2,

      subtitle: "Free",
      title: "Effortless Connectivity",
      description:
        "Easily establish a seamless and secure connection between TXTConnect and your CRM or e-commerce platform, eliminating complexities and ensuring a smooth data flow.",
      url: "/contact",
      learn: "Learn About More AI",
    },

    // {
    //   imageSrc: Images.Client3,
    //   subtitle: "Exclusive",
    //   title: "Cost-Efficient Operation",
    //   description:
    //     "Achieve cost-efficiency with our integrated bulk messaging capabilities, optimizing communication costs, and maximizing the value of your CRM or e-commerce investment.",
    //   url: "/contact",
    //   learn: "Learn About Conciegre",
    // },
    // {
    //   imageSrc: Images.Client4,
    //   subtitle: "Exclusive",
    //   title: "Technical Services",
    //   description:
    //     "From engineers and architects to product managers and bespoke integrations, our team of technical experts are here to help you manage, customize, and enhance our platform to drive exceptional results.",
    //   url: "/contact",
    //   learn: "Learn About Conciegre",
    // },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>
            Why Our Bulk Messaging Integration Is Perfect For Your Business?
          </HeadingTitle>
          {/* <HeadingDescription>
            From getting started to in-depth resources, our Client Strategy team
            provides you with ongoing guidance to set you up for SMS marketing
            success.
          </HeadingDescription> */}
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
