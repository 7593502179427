import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";
import Images from "Images";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap  max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw.h2`w-full text-2xl lg:mt-0 mt-12 sm:text-4xl md:text-5xl font-black tracking-wide text-center  leading-none`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 px-6 flex`}
`;
const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center h-[30rem] pb-12  border-2 border border-gray-200 rounded-xl mt-12 relative`}
  .imageContainer {
    ${tw` border-b text-center py-4 w-full flex justify-center  flex-shrink-0 relative`}
    img {
      ${tw`w-16 h-4`}
    }
  }
  .padding {
    ${tw`pl-4 pr-16 `}
  }
  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw` font-medium text-black mt-4 text-[0.75rem] leading-loose bg-gray-200 rounded-2xl px-2 pb-4 pt-2`}
  }
  .sub {
    ${tw` font-medium text-white mt-4 text-[0.75rem] leading-loose bg-red-500 rounded-2xl  px-2 pb-4 pt-2`}
  }
  .paddingLeft {
    ${tw`pr-4 pl-16`}
  }
`;

const InputField = styled.div`
  ${tw`absolute bottom-0 left-0 flex flex-col items-center mt-8 mx-auto max-w-xs px-2`}
  input {
    ${tw`w-full border rounded-full h-[2.25rem] px-4 focus:outline-none`}
  }
  .input-group {
    ${tw`w-full flex rounded-full overflow-hidden`}
    button {
      ${tw`bg-primary-500 text-white ml-1 px-3 h-[2.25rem] rounded-full`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: Images.Logo,
      description: "Reminder: [event name] is happening this weekend!",
      sub: "Your order is on it way to you! Track it here: [Link]",
    },
    {
      imageSrc: Images.Logo,
      description:
        "Road closure alert: [street name] is closed between [time] and [time].",
      sub: "Your appointment is scheduled for today at [time].",
    },
    {
      imageSrc: Images.Logo,
      description:
        "Important: Your account is due for renewal. Please visit [website] to renew.",
      sub: "Your payment is due on [date]. Make a payment here: [Link]",
    },
    {
      imageSrc: Images.Logo,
      description:
        "Winter weather advisory: Roads may be icy. Drive with caution. ",
      sub: "We have a new store opening near you! Come visit us at [address].",
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Informative</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              {/* <span className="textContainer"> */}
              {/* <span className="title">{card.title || "Fully Secure"}</span> */}
              <span className="padding">
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
              <span className="paddingLeft">
                <p className="sub">
                  {card.sub ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>

              <InputField>
                <div className="input-group">
                  <input disabled />
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      transform="rotate(45)"
                    >
                      <line x1="22" y1="2" x2="11" y2="13" />
                      <polygon points="22 2 15 22 11 13 2 9 22 2" />
                    </svg>
                  </button>
                </div>
              </InputField>
              {/* </span> */}
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
