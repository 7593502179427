import React, { useState } from "react";
import axios from "axios";
import Config from "Config";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";

function Platform() {
  const [formVal, setFormVal] = useState({
    email: "",
  });
  const [newsLoading, setNewsLoading] = useState(false);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormVal((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (formVal.email) {
      await newsLetter();
    }
  };

  const newsLetter = async () => {
    try {
      if (!newsLoading) {
        setNewsLoading(true);

        const res = await axios.post(
          `${Config.nodeApiUrl}/newsLetter`,
          {
            email: formVal?.email,
          },
          {
            headers: {
              for: "web",
            },
          }
        );

        setNewsLoading(false);
        toast.success(
          res?.data?.msg || "Thank you for subscribing with our platform"
        );
      }
    } catch (error) {
      setNewsLoading(false);
      toast.error(
        error?.response?.data?.msg ||
          "Something went wrong! Please try again later."
      );
    }
  };

  return (
    <div className="search-bar">
      <h2 className="search-bar-title">Discover The Platform </h2>
      <div className="search-bar-input">
        <input
          type="email"
          placeholder="Your Email Address"
          value={formVal.email}
          onChange={onChangeHandler}
          name="email"
        />
        <button
          className="search-bar-button"
          // type="submit"
          disabled={newsLoading}
          onClick={onSubmitHandler}
        >
          {!newsLoading && <span className="text">Subscribe</span>}
          {newsLoading && (
            <ReactLoading
              type={"spin"}
              color={"white"}
              height={20}
              width={20}
            />
          )}
        </button>
      </div>
    </div>
  );
}

export default Platform;
