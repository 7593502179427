import React from "react";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
const Container = tw.div`flex justify-center flex-col md:flex-row mx-auto sm:px-8 max-w-screen-xl pb-20 md:pb-24 lg:space-x-8`;
const FirstSection = tw.div`md:w-3/12  p-4 text-left order-last md:order-first`;
const SecondSection = tw.div`md:w-9/12 p-4 order-first md:order-last`;
const Heading = tw.h1`font-bold text-xl md:text-2xl lg:text-3xl xl:text-3xl  md:mb-2  leading-tight`;
const OthHeading = tw.h1`font-bold text-xl md:text-2xl lg:text-3xl xl:text-3xl  my-2 md:mt-16 md:mb-2  leading-tight`;
const Subheading = tw(SubheadingBase)`text-center md:text-left md:mb-8 mb-4 `;
const Paragraph = tw.p`mb-2 lg:mb-4 text-base  text-gray-700`;
const ComHeading = tw.h1`font-semibold text-lg md:text-xl lg:text-xl xl:text-xl text-gray-900 my-2 lg:my-1 leading-tight`;
const OrderedList = tw.ol`mb-1 lg:mb-2 lg:mt-4 mt-2 text-sm md:text-xl font-semibold  list-decimal pl-4 text-black`;
const ComParagraph = tw.p`mb-2 lg:mb-4 text-sm  text-gray-700`;
const List = tw.li` text-sm ml-1 text-gray-700`;
const ListItem = tw.li` text-base  text-black`;

// const OrderedList = tw.ol`list-decimal pl-4 mb-4`;

const UnorderedList = tw.ul`list-disc pl-4 mb-4`;
const CompanyDetails = () => {
  return (
    <Container>
      <FirstSection>
        {/* Company Details */}
        {/* <Heading>SMS Strategy</Heading>
        <Paragraph>
          Nestlé Ghana Limited started business in Ghana in 1957 under the
          trading name of Nestlé Products (Gh) Limited with the importation of
          Nestlé products such as milk and chocolates. In 1968, it was
          incorporated as Food Specialties (Gh) Limited to manufacture and
          market locally well known Nestlé brands.
        </Paragraph> */}

        <ComHeading>Posted in</ComHeading>
        <ComParagraph>SMS Strategy</ComParagraph>
        <ComHeading>Published on</ComHeading>
        <ComParagraph>2023</ComParagraph>
        <ComHeading>Written by</ComHeading>
        <ComParagraph>TXTConnect Team</ComParagraph>

        {/* Add more details about the company */}
      </FirstSection>

      <SecondSection>
        {/* <Heading>How to Request Sender ID on TXT Connect for Bulk SMS</Heading>

        <OrderedList>
          <ListItem>
            Access Sender ID Management
            <UnorderedList>
              <List>On the top navigation bar, go to 'More.'</List>
              <List>Click on 'Sender ID Management.'</List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Initiate Request
            <UnorderedList>
              <List>
                Within Sender ID Management, locate the green tab labeled
                'Request Sender ID.'
              </List>
              <List>Click on this tab to begin the request process.</List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Enter Sender ID
            <UnorderedList>
              <List>
                Ensure your sender ID is 11 characters long and can be
                alphanumeric.
              </List>
              <List>Enter your preferred Sender ID in the provided space.</List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Submit Request
            <UnorderedList>
              <List>
                Click on the 'Submit' button after entering your preferred
                sender ID.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Approval Timeframe
            <UnorderedList>
              <List>
                Understand that the approval process may take up to 24 hours.
              </List>
              <List>
                However, it often occurs much faster, typically within five
                minutes or even less.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Confirmation
            <UnorderedList>
              <List>
                Within the next five minutes, you will receive confirmation that
                your Sender ID has been approved and is ready for use.
              </List>
            </UnorderedList>
          </ListItem>
        </OrderedList> */}
        <Heading>
          Step-by-Step Guide: How to Send Bulk SMS with TXTConnect
        </Heading>

        <OrderedList>
          <ListItem>
            Welcome to TXTConnect
            <UnorderedList>
              <List>
                Log in to your TXTConnect account. If you don't have one, sign
                up to unlock the power of bulk SMS.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Navigate to Bulk SMS
            <UnorderedList>
              <List>
                On your dashboard, locate and click on the "Bulk SMS" option.
                It's your gateway to reaching a large audience effortlessly.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Choose Sender ID
            <UnorderedList>
              <List>
                Select your sender ID. If you don't have one, click on the
                adjacent tab to request a new sender ID. This is what recipients
                will see as the sender of the SMS.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Upload Contact Group
            <UnorderedList>
              <List>
                Upload your contact group. This is the list of recipients you
                want to reach. Ensure your contacts are organized for effective
                communication
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Name Your Campaign
            <UnorderedList>
              <List>
                Give your campaign a name. This will help you track and report
                on your SMS efforts easily.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Craft Your Message
            <UnorderedList>
              <List>
                Head to the message dialog box and enter the text you want to
                send. Make it concise, engaging, and to the point.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Scheduling Options
            <UnorderedList>
              <List>
                Choose whether to send your SMS instantly or schedule it for
                another day or time. Flexibility is key for effective
                communication planning.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Confirmation and Feedback
            <UnorderedList>
              <List>
                After sending, receive instant feedback. A pop-up notification
                will confirm that your messages have been sent. You can also
                check your dashboard for a detailed overview.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Check Dashboard for Status
            <UnorderedList>
              <List>
                Visit your dashboard to view the status of your messages. It
                will show you how many people in your contact group received the
                SMS successfully.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Mission Accomplished
            <UnorderedList>
              <List>
                That's it! You've successfully sent a bulk SMS using TXTConnect.
                Keep an eye on your dashboard for any additional insights and
                feedback
              </List>
            </UnorderedList>
          </ListItem>
        </OrderedList>
      </SecondSection>
    </Container>
  );
};

export default CompanyDetails;
