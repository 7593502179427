import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import Config from "Config";
import Why from "images/why.svg";
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mt-8  mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg `;
const FeatureDescription = tw.div`mt-1 text-sm`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Why Choose TXTConnect?
      {/* <span tw="text-primary-500">TXTConnect?</span> */}
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonText = "See Our Portfolio",
  primaryButtonUrl = "https://timerse.com",
  features = null,
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: "Comprehensive Services",
      description: (
        <>
          Benefit from an all-in-one platform that seamlessly integrates{" "}
          <a
            href={Config.messagingUrl}
            target="_blank"
            style={{ fontWeight: "bold" }}
            className="blue-on-hover"
          >
            Bulk SMS
          </a>{" "}
          ,{" "}
          <a
            href={Config.voiceUrl}
            target="_blank"
            style={{ fontWeight: "bold" }}
            className="blue-on-hover"
          >
            Voice SMS
          </a>
          , and{" "}
          <a
            href={Config.emailUrl}
            target="_blank"
            style={{ fontWeight: "bold" }}
            className="blue-on-hover"
          >
            Email Marketing
          </a>{" "}
          services, providing you with a versatile toolkit for effective
          communication.
        </>
      ),
    },
    {
      Icon: MoneyIcon,
      title: "User-Friendly Interface",
      description:
        " Our user-friendly platform is very easy to use, even for those new to bulk messaging. Whether you're launching an SMS blast, a voice campaign, or crafting engaging emails, our interface is designed for ease of use.",
    },
    {
      Icon: BriefcaseIcon,
      title: "Personalized Communication",
      description:
        "Connect with your audience on a personal level. Our services empower you to deliver personalized messages, fostering stronger relationships with your customers or stakeholders.",
    },
    {
      Icon: BriefcaseIcon,
      title: "Reliability and Delivery",
      description:
        "Trust in the reliability of our platform. We prioritize prompt and secure delivery of your messages, giving you peace of mind that your communication is reaching its intended audience.",
    },
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={Why} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {/* <Subheading>{subheading}</Subheading> */}
            <Heading>{heading}</Heading>
            {/* <Description>{description}</Description> */}
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  {/* <FeatureIconContainer>
                    {<feature.Icon />}
                  </FeatureIconContainer> */}
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>
                      {feature.description}
                    </FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
            {/* <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
