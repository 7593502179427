import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/ThreeColWithSideImageWithPrimaryBackground";
import Steps from "components/features/TwoColWithSteps";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/MiniCenteredFooter";
import FAQ from "components/faqs/SingleCol.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import { BsArrowRepeat } from "react-icons/bs";
import { RiCustomerService2Line } from "react-icons/ri";
import { TfiWorld } from "react-icons/tfi";
import Images from "Images";
import ScrollToTopButton from "./ScrollToTopButton";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`text-primary-500`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block text-sm mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={
          <>
            <HighlightedText>Mass Text Messaging</HighlightedText> service for
            Customized Campaigns
          </>
        }
        description="Push event invites with mass SMS & MMS promotions, fundraising, and more. Best service rates and all widely known CRM integrations. Sustain customer loyalty and repeat business by delivering the right message on time always."
        imageSrc={Images.HomePage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
        watchVideoButtonText="Meet The Chefs"
      />
      <ScrollToTopButton />
      <MainFeature
        imageSrc={Images.Message}
        subheading={<Subheading></Subheading>}
        heading={
          <>
            What is
            <wbr /> <HighlightedText>Mass Text Messaging</HighlightedText>
          </>
        }
        description={
          <Description>
            A mass text message is one which an agency or company sends to a
            wide number of contacts/constituents at once. Mass text messaging
            helps you to send a single custom text message to whole groups of
            people, large or small.
            <br />
            <br />
            Scheduling an instant mass text can save you the time and energy it
            would take to go through your full contacts list and send individual
            messages. There are a lot of ways you can use mass messaging,
            depending on your objectives.
            <br />
            <br /> Sending event invitations, for example, to receive RSVPs and
            event updates, GOTV emails, requests for donations, sale alerts, and
            promotional codes. These are just a few of the ways that
            organizations use mass texts to connect with the public.
          </Description>
        }
        // buttonRounded={false}
        textOnLeft={false}
        // primaryButtonText="Latest Offers"

        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <MainFeature2
        subheading=""
        heading={
          <>
            Benefits <HighlightedText></HighlightedText>
          </>
        }
      />

      <Steps
        subheading={<Subheading></Subheading>}
        heading={
          <>
            How It
            <wbr /> <HighlightedText>Works</HighlightedText>
          </>
        }
        description={<Description></Description>}
        // buttonRounded={false}
        // textOnLeft={false}
        // primaryButtonText="Latest Offers"
        imageSrc={Images.Mass}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />

      <Steps
        subheading={<Subheading></Subheading>}
        steps={[
          {
            heading: "Shor & Clear Message",
            description:
              "The message is short and conversational. Imagine the way you would talk to a friend when crafting your message.",
          },
          {
            heading: "Relevant CTA",
            description:
              "Have a simple call to action (CTA) that makes it easy for your users to do what you both want them to do.",
          },
          {
            heading: "Schedule it for the right time",
            description:
              "A text at 3 AM is not likely to be well received. Use the CallHub analysis or analytics software to help you determine the best times to send your messages.",
          },
          {
            heading: "Make your messages relevant",
            description:
              "When crafting your message, be sure to focus on its relevance to your users. If they don’t feel compelled to act on your message because they don’t feel like it applies to them or don’t feel motivated by it, they aren’t likely to act in the way you want them to.",
          },
        ]}
        heading={
          <>
            What makes a great
            <wbr /> <HighlightedText>mass text message</HighlightedText>
          </>
        }
        description={<Description></Description>}
        // buttonRounded={false}
        // textOnLeft={false}
        // primaryButtonText="Latest Offers"
        imageSrc={Images.Great_Message}
        textOnLeft={false}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />

      <MainFeature2
        description="In under 3 minutes, you can have a campaign producing tangible results! You can start sending texts with just a browser. You can check on your campaign with some free credits, without investing a cent."
        heading={<>Why you should use it</>}
        cards={[
          {
            icon: <BsArrowRepeat tw="w-6 h-6 text-gray-800" />,
            title: "Integrate with your CRM",
            description: "Integrates with many popular CRMs",
          },
          {
            icon: <RiCustomerService2Line tw="w-6 h-6 text-gray-800" />,
            title: "Intelligent help",
            description:
              "Setting up your first text messaging campaign is something most people will have little difficulty doing, and our detailed documentation and guides will walk you through every step. But if you need assistance, our customer service engineers are always happy to assist you.",
          },

          {
            icon: <TfiWorld tw="w-6 h-6 text-gray-800" />,
            title: "Easy to navigate",
            description:
              "Withover 15,000 agencies and individuals, from age 18 to 80, using CallHub to send text messages and to make calls, we’re sure you’ll find the process simple and straightforward.",
          },
        ]}
      />

      <Footer />
    </AnimationRevealPage>
  );
};
