import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import HeaderBase, {
  NavLinks,
  NavLink,
  PrimaryLink,
} from "components/headers/light.js";
import Header from "../headers/light.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import Images from "Images";
import RevenueCalculator from "./RevenueCalculator.js";
import ContactUs from "components/revenue/ContactUs.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "components/misc/Layouts";

// const Container = styled.div`
//   ${tw`relative `};
// bg-[#FFF382]
// `;
const Container = tw(ContainerBase)` text-gray-100 -mx-8 px-8 `;
const HeroContainer = tw.div` relative sm:px-8 mx-auto h-full flex flex-col max-w-screen-xl   py-20 md:py-24`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

// const Heading = styled.h1`
//   ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black  leading-none `}
//   span {
//     ${tw`inline-block mt-2`}
//   }
// `;
const Subheading = tw(SubheadingBase)`text-center md:text-left mb-8 `;

const Description = tw.p`mt-6 text-center  text-sm md:text-base lg:text-xl font-medium leading-relaxed text-secondary-800 `;
const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-md`,
]);
const PrimaryAction = tw.button`rounded-full px-8 py-3 my-8 text-sm sm:text-base sm:my-10 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl md:text-left text-center text-gray-900 md:mb-8 mb-4 leading-tight`;
const Paragraph = tw.p`mb-2  text-base  text-gray-700`;
export default ({
  user,
  buttonRounded = true,
  roundedHeaderButton,
  primaryButtonUrl = "/contact",
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#">About</NavLink>
      <NavLink href="#">Blog</NavLink>
      <NavLink href="#">Locations</NavLink>
      <NavLink href="#">Pricing</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/#">Hire Us</PrimaryLink>
    </NavLinks>,
  ];

  return (
    <>
      {/* <Header roundedHeaderButton={roundedHeaderButton} user={user} /> */}
      <Container>
        <HeroContainer>
          <Content>
            {/* <Subheading>AUDIENCE MANAGER</Subheading> */}
            <Heading>Revenue And Subscriber Forecast Calculator</Heading>
            <Paragraph>
              Estimate the potential return on investment of your bulk messaging
              campaigns.
            </Paragraph>
            <RevenueCalculator />
          </Content>
        </HeroContainer>
      </Container>
    </>
  );
};
