import React from "react";
import tw from "twin.macro";

import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";
import styled from "styled-components";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
const Container = tw(
  ContainerBase
)`bg-primary-900 text-gray-100 -mx-8 px-8 mt-32 md:mt-0`;
const HeadingContainer = tw.div``;
const Heading = tw(
  SectionHeading
)`sm:text-3xl md:text-4xl lg:text-5xl leading-none`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(
  SectionDescription
)`text-gray-400 text-center mx-auto max-w-screen-md`;
const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md mx-auto`;
const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`items-center justify-center mt-8 text-sm inline-block  bg-white text-red-500`,
  props.buttonRounded && tw`rounded-full`,
]);

export default ({
  subheading = "",

  description = "",
  primaryButtonUrl = "/contact",
  buttonRounded = true,
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>
            Ready to build your SMS program with
            <br /> confidence?
          </Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          <PrimaryButton
            buttonRounded={buttonRounded}
            as="a"
            href={primaryButtonUrl}
          >
            Let's Get Started
          </PrimaryButton>
        </StatsContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
