import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import Images from "Images";
import { FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex flex-col items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-40 mb-4`;
const LogoText = tw.h5`text-base text-gray-500 tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 cursor-pointer border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;

export default ({ onProductLinkClick }) => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={Images.LogoWhite} />
            <LogoText>
              Personalized communication to the audience you value
            </LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link href="/">Home</Link>
            <Link onClick={onProductLinkClick} className="footer-product">
              Products
            </Link>
            <Link href="/contact">Contact Us</Link>
            <Link href="/about">About Us</Link>
            <Link href="/#pricing">Pricing</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/txtconnect.net">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/txtconnect_">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/txtconnectgh/">
              <FaLinkedinIn />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/txtconnect_/">
              <FaInstagram />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright {new Date().getFullYear()}, TXTConnect All Rights
            Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
