import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import Images from "Images";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-2xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const HeadingSubTitle = tw.h2`text-2xl mt-12 lg:mt-4 sm:text-4xl md:text-3xl text-gray-700  tracking-wide font-normal text-center leading-none`;
const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-5/12 flex-shrink-0 h-80 md:h-96  bg-no-repeat bg-contain bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-12  lg:my-0`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-xl mx-4 sm:mx-8 md:mx-4 lg:mx-12`;

const Title = tw.h4`text-2xl sm:text-2xl lg:text-3xl font-bold text-gray-900 leading-tight`;
const Description = tw.p`mt-2 text-base font-medium `;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: Images.Audience,

      subtitle: "Paid",
      title: "Capture Critical Subscriber Data, Easily",
      description:
        "Quickly collect subscriber behavior with our custom tag designed to work with every major e-commerce storefront. Bridge subscribers across devices and sessions, resulting in hyper-personalized flows that drive engagement.",
      url: "/contact",
      learn: "Learn About Audience Manager",
    },

    {
      imageSrc: Images.Audience1,

      subtitle: "Free",
      title: "Build A Complete Picture Of Every Customer",
      description:
        "Use TXTConnect Consumer segmentation to automatically create detailed consumer profiles with zero- and first-party data, captured engagement, and more collected via various integrations. View the most relevant subscriber details directly within the platform to get a clear understanding of each customer.",
      url: "/contact",
      learn: "Learn About More AI",
    },

    {
      imageSrc: Images.Audience3,
      subtitle: "Exclusive",
      title: "Drive More Sales With Smarter Segmentation",
      description:
        "Get the right messages to the right customers with dynamic segmenting based on behaviors, demographics, and more. Pull from a library of pre-built segment templates (or create your own) to hit the ground running and maximize ROI, fast.",
      url: "/contact",
      learn: "Learn About Conciegre",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>
            Boost Engagement, Increase Revenue <br />
            And Inspire Loyalty
          </HeadingTitle>
          <HeadingSubTitle>
            Target the perfect audience every time with best-in-class identity
            that drives <br /> results.
          </HeadingSubTitle>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
