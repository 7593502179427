import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import Images from "Images.js";
import { Link } from "react-router-dom";
const CardLink = tw(Link)`block`;
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded`,
]);
const Category = tw.div`mt-4 text-secondary-100 font-bold text-sm`;
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg`;
// const Link = tw.a`inline-block mt-2 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default () => {
  const blogPosts = [
    {
      imageSrc: Images.CaseStudy1_thumb,
      category: "Case Study 1",
      title: "Nestle IDEAL Trip to Dubai Promo 2023",
      url: "https://timerse.com",
    },
    // {
    //   imageSrc: Images.Case1,
    //   category: "Food & Beverage",
    //   title:
    //     "How BIBIBOP Uses SMS to Improve the Customer Journey, Drive App Downloads, and Keep Customers Coming Back",
    //   url: "https://reddit.com",
    // },
    // {
    //   imageSrc: Images.Case2,
    //   category: "Apparel & Footwear",
    //   title:
    //     "How Monsoon Created New Demand With SMS Marketing, Increasing Their Revenue by 20%",
    //   url: "https://timerse.com",
    // },
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Explore Our Case Studies</HeadingTitle>
          <HeadingDescription>
            Find inspiration, no matter your industry or marketing goal.
          </HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn navigate="/case-study/nestle">
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <CardLink to="/case-study/nestle">
                <Card>
                  <Image imageSrc={post.imageSrc} />
                  <Category>{post.category}</Category>
                  <Title>{post.title}</Title>
                  {/* <Link href={post.url}>Read Post</Link> */}
                </Card>
              </CardLink>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
