import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import Images from "Images";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-2xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-5/12 flex-shrink-0 h-80 md:h-96  bg-no-repeat bg-contain bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-12  lg:my-0`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-xl mx-4 sm:mx-8 md:mx-4 lg:mx-12`;

const Title = tw.h4`text-2xl sm:text-2xl lg:text-3xl font-bold text-gray-900 leading-tight`;
const Description = tw.p`mt-2 text-base font-medium `;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: Images.Integration1,

      subtitle: "Paid",
      title: "Launch And Scale With Speed",
      description:
        "Connect and use TXTConnect’s flexible API to build a bespoke integration tailored to your needs. Get hands-on support and guidance to make sure you’re up and running quickly.",
      url: "/contact",
      learn: "Learn About Audience Manager",
    },

    {
      imageSrc: Images.Integration2,

      subtitle: "Free",
      title: "Unify Your Data",
      description:
        "Sync product, customer, and historical data between all of your marketing tools and TXTConnect. Create dynamic segments using data from marketing campaigns, loyalty programs, and more.",
      url: "/contact",
      learn: "Learn About More AI",
    },

    {
      imageSrc: Images.Integration3,
      subtitle: "Exclusive",
      title: "Personalize Every Message",
      description:
        "Build journeys with triggered messages for critical customer moments like back-in-stock, price drops, or product launches. Provide support and personalized information via SMS by connecting TXTConnect API with your platform.",
      url: "/contact",
      learn: "Learn About Conciegre",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
