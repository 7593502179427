import React, { useState, useEffect } from "react";
import ConversionVisitorSubscriber from "components/growth/ConversionVisitorSubscriber";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import GrowthAnalytics from "components/growth/GrowthAnalytics";
import Strategy from "components/growth/Strategy";
import EngagementStrategy from "components/growth/EngagementStrategy";
import CustomerEngagement from "components/growth/CustomerEngagement";
import Marketing from "components/growth/Marketing";
import SuccessStory from "components/growth/SuccessStory";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import styled from "styled-components";
const MaxWidth = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;
export default ({}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeNavLink, setActiveNavLink] = useState(""); // Store active nav link state

  // Function to handle Product link click in Footer and update Navbar state
  const handleProductLinkClick = () => {
    setActiveNavLink("Products"); // Set active link in Navbar
    setIsSubMenuOpen(true); // Open the Products dropdown
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [user, setUser] = useState(false);
  return (
    <AnimationRevealPage>
      {/* <MaxWidth> */}
      <Header
        roundedHeaderButton={true}
        user={user}
        isSubMenuOpen={isSubMenuOpen}
        activeNavLink={activeNavLink}
        setActiveNavLink={setActiveNavLink}
        setIsSubMenuOpen={setIsSubMenuOpen}
      />
      <ConversionVisitorSubscriber />
      <GrowthAnalytics />

      <CustomerEngagement />
      {/* <EngagementStrategy />
      <Marketing />
      <SuccessStory /> */}

      <Strategy />
      <Footer onProductLinkClick={handleProductLinkClick} />
      {/* </MaxWidth> */}
    </AnimationRevealPage>
  );
};
