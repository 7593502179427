import React from "react";
import tw from "twin.macro";

import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
  ContentWithPaddingSm,
} from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(
  ContainerBase
)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;

const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(
  SectionDescription
)`text-gray-400 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div` flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-xl justify-between mx-auto`;
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`;
const StatKey = tw.div`text-xl font-medium`;
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`;

export default ({
  stats = [
    {
      key: "Of customers rate Attentive’s ",
      key1: "integrations higher than their",
      key2: " previous provider",
      value: "73%",
    },
    {
      key: "Integrations with",
      key1: "leading tech partners",

      value: "100+",
    },
    {
      key: "Revenue recovered",
      key1: "from abandoned carts via e-",
      key2: "commerce integrations",

      value: "$1.2B",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingSm>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
              <StatKey>{stat.key1}</StatKey>
              <StatKey>{stat.key2}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
      </ContentWithPaddingSm>
    </Container>
  );
};
