import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";

import Images from "Images";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-2xl mx-auto py-20 sm:py-12 lg:py-24`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-5/12 flex-shrink-0 h-80 md:h-96  bg-no-repeat bg-contain bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-12  lg:my-0`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-xl mx-4 sm:mx-8 md:mx-4 lg:mx-12`;

const Title = tw.h4`text-2xl sm:text-2xl lg:text-3xl font-bold text-gray-900 leading-tight`;
const Description = tw.p`mt-2 text-base font-medium `;
const Link = tw.a`inline-block mt-4 text-sm   cursor-pointer text-primary-500 rounded-md hover:bg-primary-500 hover:text-white transition duration-300 border border-primary-500 py-2 px-4 hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: Images.Con,
      subtitle: "Identify and engage more of your on-site browsers",
      title: "Provide accurate answers instantly",
      description:
        "Quickly provide high-quality responses 24/7 using conversational AI and AI-assisted live agents. Give your team time back and greater flexibility knowing our team is managing all incoming messages.",
      url: "/contact",
    },

    {
      imageSrc: Images.Con1,
      subtitle: "Create elevated experiences that drive sales",
      title: "Reach customers wherever they are",
      description:
        "Transform purchases by giving every unique customer a tailored response designed to drive conversions. E-commerce integrations provide subscriber purchase history and browsing data that allows AI to identify opportunities to cross- and up-sell in real-time.",
      url: "/contact",
    },

    {
      imageSrc: Images.Con2,
      subtitle:
        "An industry-first, unified composer for email and SMS that makes orchestration and retargeting fast and easy",
      title: "Always be on brand",
      description:
        "Get up and running quickly by providing your unique brand attributes—such as voice, tone, and product catalog—and we’ll automatically train our agents and AI. Refine future conversations via feedback and analyze performance with conversational insights.",
      url: "/contact",
    },
    {
      imageSrc: Images.Con3,
      subtitle:
        "An industry-first, unified composer for email and SMS that makes orchestration and retargeting fast and easy",
      title: "Live chat built for mobile",
      description:
        "Say goodbye to clunky on-site apps and transition to a seamless SMS thread with LiveSMS™. Provide real-time support for customers with intent to spend, driving more revenue and growing your subscriber list.",
      url: "/contact",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Title>{card.title}</Title>

                <Description>{card.description}</Description>
                {card.learn && <Link href={card.url}>{card.learn}</Link>}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
