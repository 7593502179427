import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import { HiUsers } from "react-icons/hi";
import { AiFillMessage } from "react-icons/ai";
import { BsShieldFillCheck } from "react-icons/bs";

const Subheading = tw(SubheadingBase)`text-center`;
const Heading = tw.h1`font-bold text-center text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;

const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-sm mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-800 font-medium text-2xl`;
const CustomerName = tw.p`mt-5 text-gray-700 text-sm `;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "Testimonials",
  heading = "Customer's Review",
  testimonials = [
    {
      icon: <HiUsers tw="w-12 h-12 text-[#e42f17]" />,
      quote: "Gain the audience",
      customerName: "Start a dialog with your audience, or ask them to act.",
    },
    {
      icon: <AiFillMessage tw="w-12 h-12 text-[#e42f17]" />,
      quote: "Have your message read",
      customerName: "Text messages are opened",
    },
    {
      icon: <BsShieldFillCheck tw="w-12 h-12 text-[#e42f17]" />,
      quote: "Secure Significant Services",
      customerName:
        "Save time with mass text to get your message out to supporters, and save money too, charging just as you go.",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                {testimonial.icon}
                <Quote>{testimonial.quote}</Quote>
                <CustomerName>{testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
