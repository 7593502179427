import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import Images from "Images";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import Config from "Config";
const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-2xl mx-auto py-20 sm:py-12 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center `;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-5/12 flex-shrink-0 h-80 md:h-96  bg-no-repeat bg-contain bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-12  lg:my-0`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-xl mx-4 sm:mx-8 md:mx-4 lg:mx-12`;

const Title = tw.h4`text-2xl sm:text-2xl lg:text-3xl font-bold text-gray-900 leading-tight`;
const Description = tw.p`mt-2 text-base font-medium `;
const Link = tw.a`inline-block mt-4 text-sm   cursor-pointer text-primary-500 rounded-md hover:bg-primary-500 hover:text-white transition duration-300 border border-primary-500 py-2 px-4 hover:border-primary-500`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-4 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;
const Iframe = tw.iframe`w-full h-full`;
const VideoContainer = tw.div`rounded relative aspect-auto h-80 md:h-96 border  lg:self-end md:w-1/2 lg:w-5/12 xl:w-5/12 bg-contain bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-12  lg:my-0`;
const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const Paragraph = tw.p`my-5 lg:my-8 text-base  text-gray-700`;
const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
export default () => {
  const cards = [
    {
      imageSrc: Images.Newsletter,
      subtitle: "Identify and engage more of your on-site browsers",
      title: "Newsletter Distribution",
      description:
        "Keep your subscribers informed and engaged with your brand by sharing your latest news, updates, and promotions through strategically crafted newsletters. Our email automation feature allows you to schedule newsletters at optimal times for your audience, maximizing open rates and click-through rates.",
      //     url: "/contact",
      //     learn: "How it Works",
      //     watchVideoButtonText:"Watch Video",
      // watchVideoYoutubeUrl:"https://www.youtube.com/embed/46ZjlVwxvfI",
    },

    {
      imageSrc: Images.Lead,
      subtitle:
        "An industry-first, unified composer for email and SMS that makes orchestration and retargeting fast and easy",
      title: "Lead Nurturing",
      description:
        "With our email marketing platform, you can nurture potential customers with personalized and targeted email sequences, tailored to their interests and behavior, to educate them about your brand, build trust, and identify those most likely to convert, allowing you to focus your sales efforts effectively",
      url: "/contact",
      //     learn: "How it Works",
      //     watchVideoButtonText:"Watch Video",
      // watchVideoYoutubeUrl:"https://www.youtube.com/embed/46ZjlVwxvfI",
    },

    {
      imageSrc: Images.Customer,
      subtitle: "Easily create personalized campaigns that convert",
      title: "Customer Retention",
      description:
        "Use our email marketing service to regularly engage your subscribers with informative and engaging content, keeping them informed about your brand's latest offerings and promotions. Reward their loyalty with exclusive discounts and special offers, demonstrating your gratitude for their continued patronage.",
      //     learn: "How it Works",
      //     watchVideoButtonText:"Watch Video",
      // watchVideoYoutubeUrl:"https://www.youtube.com/embed/46ZjlVwxvfI",
    },
    {
      imageSrc: Images.Ecommerce,
      subtitle: "Identify and engage more of your on-site browsers",
      title: "E-commerce Campaigns",
      description:
        "Capture attention and drive traffic to your e-commerce store with captivating email campaigns that showcase your products and services in the most enticing manner. Our platform allows you to attach images that will make your email campaign visually appealing to your subscribers",
      //     learn: "How it Works",
      //     watchVideoButtonText:"Watch Video",
      // watchVideoYoutubeUrl:"https://www.youtube.com/embed/46ZjlVwxvfI",
    },
    {
      // imageSrc:
      //   "https://images.unsplash.com/photo-1509824227185-9c5a01ceba0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
      imageSrc: Images.AttEmail3,
      subtitle: "Exclusive",
      title: "How It Works",
      description: (
        <>
          Launch your email marketing campaign with{" "}
          <a
            href={Config.domain}
            target="_blank"
            style={{ fontWeight: "bold" }}
            className="blue-on-hover"
          >
            TXTConnect
          </a>{" "}
          in just a few easy steps
        </>
      ),
      url: "/guide/email-marketing",
      learn: "How it Works",
      //     watchVideoButtonText:"Watch Video",
      // watchVideoYoutubeUrl:"https://www.youtube.com/embed/46ZjlVwxvfI",
    },
    {
      // imageSrc:
      //   "https://images.unsplash.com/photo-1509824227185-9c5a01ceba0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
      // imageSrc: Images.AttEmail2,
      subtitle: "Exclusive",
      title: "Explainer Video",
      description: (
        <>
          Watch our Email Marketing Explainer Video to see how you can transform
          your email campaigns with{" "}
          <a
            href={Config.domain}
            target="_blank"
            style={{ fontWeight: "bold" }}
            className="blue-on-hover"
          >
            TXTConnect.
          </a>
        </>
      ),
      url: Config.url,
      // learn: "Watch Videos",
      watchVideoYoutubeUrl: "https://www.youtube.com/embed/46ZjlVwxvfI",
      // watchVideoButtonText: "Watch Video",
      // watchVideoYoutubeUrl: "https://www.youtube.com/embed/46ZjlVwxvfI",
    },
  ];
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");

  // const toggleModal = () => setModalIsOpen(!modalIsOpen);
  const toggleModal = (videoUrl = "") => {
    setSelectedVideoUrl(videoUrl);
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Use Our Email Marketing Services For</HeadingTitle>

          {/* <HeadingDescription>
            A more powerful, unified, and innovative messaging platform that
            gets results
          </HeadingDescription> */}
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              {card.imageSrc && <Image imageSrc={card.imageSrc} />}
              {!card.imageSrc && (
                <VideoContainer>
                  <Iframe
                    src="https://www.youtube.com/embed/3FC9qoG3TwA"
                    title="Explainer Video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></Iframe>
                </VideoContainer>
              )}

              <Details>
                <Title>{card.title}</Title>

                <Paragraph>{card.description}</Paragraph>
                <Actions>
                  {card.watchVideoButtonText && (
                    <WatchVideoButton
                      onClick={() => toggleModal(card.watchVideoYoutubeUrl)}
                    >
                      <span className="playIconContainer">
                        <PlayIcon className="playIcon" />
                      </span>
                      <span className="playText">
                        {card.watchVideoButtonText}
                      </span>
                    </WatchVideoButton>
                  )}
                  {card.learn && (
                    <Link href={card.url} target="_blank">
                      {card.learn}
                    </Link>
                  )}
                </Actions>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={true}
      >
        <CloseModalButton onClick={toggleModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <div className="content">
          {selectedVideoUrl && (
            <ResponsiveVideoEmbed url={selectedVideoUrl} tw="w-full" />
          )}
        </div>
      </StyledModal>
    </Container>
  );
};
