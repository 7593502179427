import React from "react";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
const Container = tw.div`flex justify-center flex-col md:flex-row mx-auto sm:px-8 max-w-screen-xl pb-20 md:pb-24 lg:space-x-8`;
const FirstSection = tw.div`md:w-3/12  p-4 text-left order-last md:order-first`;
const SecondSection = tw.div`md:w-9/12 p-4 order-first md:order-last`;
const Heading = tw.h1`font-bold text-xl md:text-2xl lg:text-3xl xl:text-3xl  md:mb-2  leading-tight`;
const OthHeading = tw.h1`font-bold text-xl md:text-2xl lg:text-3xl xl:text-3xl  my-2 md:mt-16 md:mb-2  leading-tight`;
const Subheading = tw(SubheadingBase)`text-center md:text-left md:mb-8 mb-4 `;
const Paragraph = tw.p`mb-2 lg:mb-4 text-base  text-gray-700`;
const ComHeading = tw.h1`font-semibold text-lg md:text-xl lg:text-xl xl:text-xl text-gray-900 my-2 lg:my-1 leading-tight`;
const OrderedList = tw.ol`mb-1 lg:mb-2 lg:mt-4 mt-2 text-sm md:text-xl font-semibold  list-decimal pl-4 text-black`;
const ComParagraph = tw.p`mb-2 lg:mb-4 text-sm  text-gray-700`;
const List = tw.li` text-sm ml-1 text-gray-700`;
const ListItem = tw.li` text-base  text-black`;

// const OrderedList = tw.ol`list-decimal pl-4 mb-4`;

const UnorderedList = tw.ul`list-disc pl-4 mb-4`;
const CompanyDetails = () => {
  return (
    <Container>
      <FirstSection>
        {/* Company Details */}
        {/* <Heading>SMS Strategy</Heading>
        <Paragraph>
          Nestlé Ghana Limited started business in Ghana in 1957 under the
          trading name of Nestlé Products (Gh) Limited with the importation of
          Nestlé products such as milk and chocolates. In 1968, it was
          incorporated as Food Specialties (Gh) Limited to manufacture and
          market locally well known Nestlé brands.
        </Paragraph> */}

        <ComHeading>Posted in</ComHeading>
        <ComParagraph>SMS Strategy</ComParagraph>
        <ComHeading>Published on</ComHeading>
        <ComParagraph>2023</ComParagraph>
        <ComHeading>Written by</ComHeading>
        <ComParagraph>TXTConnect Team</ComParagraph>

        {/* Add more details about the company */}
      </FirstSection>

      <SecondSection>
        <Heading>
          Here is a step-by-step guide on how to send a voice message on
          TXTConnect
        </Heading>

        <OrderedList>
          <ListItem>
            Access the Voice Messaging Feature
            <UnorderedList>
              <List>
                Log in to your TXTConnect account using your email address and
                password.
              </List>
              <List>
                From the dashboard, navigate to the "Voice" tab located at the
                top of the screen.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Prepare the Voice Message
            <UnorderedList>
              <List>
                The caller ID for voice messages is provided by the service
                provider and cannot be customized.
              </List>
              <List>
                Enter the recipient's phone number in the designated field.
              </List>
              <List>
                Upload the audio file containing the voice message you want to
                send.
              </List>
              <List>
                Click on the "Play" button to preview the voice message before
                sending it.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Send the Voice Message
            <UnorderedList>
              <List>
                Once you're satisfied with the preview, click on the "Send"
                button to deliver the voice message to the recipient.
              </List>
            </UnorderedList>
          </ListItem>
        </OrderedList>
        <OthHeading>
          Here is a step-by-step guide on how to send a bulk voice message on
          TXTConnect
        </OthHeading>

        <OrderedList>
          <ListItem>
            Sending Bulk Voice Messages
            <UnorderedList>
              <List>
                To send a voice message to multiple recipients, go to the
                dashboard and select the "Voice" tab.
              </List>
              <List>
                Click on the "Send Voice" option and choose "Upload Contact
                Group."
              </List>
              <List>
                Provide your company name for identification purposes.
              </List>
              <List>
                Upload the audio file containing the voice message you want to
                send to all contacts in the group.
              </List>
              <List>
                Click on the "Play" button to preview the voice message before
                sending it to the group.
              </List>
            </UnorderedList>
          </ListItem>
        </OrderedList>

        <OthHeading>
          Here is a step-by-step guide on how to send a schedule voice message
          on TXTConnect
        </OthHeading>

        <OrderedList>
          <ListItem>
            Scheduling Voice Messages
            <UnorderedList>
              <List>
                If you want to schedule a voice message to be sent at a specific
                time, click on the "Schedule Voice" checkbox.
              </List>
              <List>
                Enter the desired date and time for the voice message to be
                delivered to the recipient or group.
              </List>
            </UnorderedList>
          </ListItem>
        </OrderedList>
      </SecondSection>
    </Container>
  );
};

export default CompanyDetails;
