import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import Images from "Images";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Modal from "react-modal";
import axios from "axios";
import Config from "../Config";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`w-32 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const LoginHeading = tw.h2`text-xl xl:text-2xl font-bold`;
const SectionHeading = tw.h2`xl:text-2xl text-xl  mb-4 tracking-wide text-center font-bold`;
// const SectionHeading = tw.h2`text-3xl  mb-4 tracking-wide text-center`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;
const SuccessBadge = tw.div`
inline-block
  bg-green-200
  text-green-600
  font-semibold
  px-6
  py-2
  rounded-lg
  text-xs
  xl:text-base
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 -translate-x-1/2 absolute  top-1/2 left-1/2  whitespace-nowrap z-10`;

const Form = tw.form`mx-auto max-w-xs`;
// const Form = tw.form`bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4`;
const Input = tw.input`
  appearance-none 
  border rounded 
  w-full py-2 px-3 
  text-gray-700 leading-tight 
  focus:outline-none focus:shadow-outline
`;

const Label = tw.label`block text-gray-700 text-sm font-bold mb-2`;
const InputContainer = tw.div`relative`;

// const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
//
const IllustrationContainer = tw.div`sm:rounded-r-lg overflow-hidden bg-purple-100 text-center hidden lg:flex flex-1`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`w-full h-full bg-cover bg-right bg-no-repeat relative `}
   opacity:0.4
`;

const TextOverlay = styled.div`
  ${tw`absolute top-1/2 left-1/2 ml-64 transform -translate-x-1/2 -translate-y-1/2 text-3xl font-bold text-center  `}
  //  background-color: rgba(255, 255, 255, 0.3);

  h1 {
    ${tw`text-2xl  p-6`}
  }

  p {
    ${tw`text-lg`}
  }
`;
const CustomersLogoStrip = styled.div`
  ${tw`!mt-0 max-w-4xl mx-auto text-center`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider  text-gray-500`}
  }
  // img {
  //   ${tw`w-full`}
  // }
  img {
    ${tw`w-full`}
    background-color: rgba(255, 255, 255, 0.6);
  }
`;

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "Log In",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Log In With Google",
      url: "https://google.com",
    },
    {
      iconImageSrc: Images.Fb,
      text: "Log In With Facebook",
      url: "https://www.facebook.com",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Log In With Twitter",
      url: "https://twitter.com",
    },
  ],
  submitButtonText = "Log In",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "#",
  signupUrl = "/sign-up",
}) => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [forgetPasswordNotice,setForgetPasswordNotice] = useState(false);
  const [formVal, setFormVal] = useState({
    emailMobile: "",
    password: "",
  });
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const responseGoogle = (res) => {
    // console.log("google", res);
    if (res?.tokenId) {
      loginUser("2", res?.googleId);
    }
  };
  const responseFacebook = (res) => {
    // console.log("fb", res);

    if (res?.id) {
      loginUser("3", res?.id);
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormVal((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (forgotPassword) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formVal.emailMobile)) {
        return toast.error("Please enter a valid email address");
      }
      forgotPasswordApi();
    }

    //Login
    else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formVal.emailMobile)) {
        return toast.error("Please enter a valid email address");
      }
      if (formVal.password.length < 8) {
        return toast.error("Password should be atleast 8 characters");
      }

      loginUser();
    }
  };

  const loginUser = async (mode, loginId) => {
    try {
      if (!loginLoading) {
        setLoginLoading(true);
        const res = await axios.post(`${Config.apiUrl}/login`, {
          loginId: loginId || formVal?.emailMobile,
          password: formVal?.password || "",
          mode: mode || "1",
        });

        setLoginLoading(false);

        localStorage.setItem("user", JSON.stringify(res?.data));
        window.location = Config.domain + "/cms/";
      }
    } catch (error) {
      // console.log("register user error: ", error?.response);
      setLoginLoading(false);
      toast.error(
        error?.response?.data?.msg ||
          "Something went wrong! please try again later."
      );
    }
  };

  const forgotPasswordApi = async () => {
    try {
      if (!loginLoading) {
        setLoginLoading(true);
        const res = await axios.post(`${Config.apiUrl}/forgot-password`, {
          registeredId: formVal?.emailMobile,
        });

        setLoginLoading(false);
        setForgotPassword(false);
        setForgetPasswordNotice(true);
        toast.success(res?.data?.msg || "Success");
      }
    } catch (error) {
      // console.log("register user error: ", error?.response);
      setLoginLoading(false);
      toast.error(
        error?.response?.data?.msg ||
          "Something went wrong! please try again later."
      );
    }
  };

  return (
    <AnimationRevealPage>
      <Container style={{ backgroundImage: `url("/images/sign-up-bg.png")` }}>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={Images.Logo} />
            </LogoLink>
            <MainContent>
              <LoginHeading>
                {forgotPassword ? "Forgot Password" : "Log In"}
              </LoginHeading>
              {
                forgetPasswordNotice && (
                  <SuccessBadge>
                      We have sent a new password to your email address.
                      Please check your inbox.
                  </SuccessBadge>
                )
              }
              <FormContainer>
                <Form onSubmit={onSubmitHandler}>
                  {!forgotPassword && (
                    <>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": {
                            // my: 1,
                            width: "100%",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#C4C5C5",
                            },

                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#C4C5C5",
                            },
                          "& .MuiFilledInput-root:hover": {
                            borderColor: "#C4C5C5",
                          },
                          "& .MuiFilledInput-root.Mui-focused": {
                            borderColor: "#C4C5C5",
                          },
                          "& .MuiInputBase-root:hover": {
                            borderColor: "#C4C5C5",
                          },
                          "& .MuiInputBase-root.Mui-focused": {
                            borderColor: "#C4C5C5",
                          },
                          "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                            color: "#374151",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          // style={{ color: "gray" }}
                          id="email"
                          label="Email"
                          variant="outlined"
                          value={formVal.emailMobile}
                          // defaultValue={formVal.emailMobile}
                          name="emailMobile"
                          onChange={onChangeHandler}
                          required
                          size="small"
                          margin="normal"
                          type="text"
                          autoComplete="off"
                        />

                        <TextField
                          label="Password"
                          variant="outlined"
                          type="password"
                          id="password"
                          value={formVal.password}
                          // defaultValue={formVal.password}
                          name="password"
                          onChange={onChangeHandler}
                          required
                          size="small"
                          margin="normal"
                          autoComplete="off"
                        />
                      </Box>
                    </>
                  )}
                  {forgotPassword && (
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          // my: 1,
                          width: "100%",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#C4C5C5",
                          },

                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#C4C5C5",
                          },
                        "& .MuiFilledInput-root:hover": {
                          borderColor: "#C4C5C5",
                        },
                        "& .MuiFilledInput-root.Mui-focused": {
                          borderColor: "#C4C5C5",
                        },
                        "& .MuiInputBase-root:hover": {
                          borderColor: "#C4C5C5",
                        },
                        "& .MuiInputBase-root.Mui-focused": {
                          borderColor: "#C4C5C5",
                        },
                        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                          color: "#374151",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        label="Registered Email"
                        variant="outlined"
                        type="text"
                        id="emailMobile"
                        value={formVal.emailMobile}
                        name="emailMobile"
                        onChange={onChangeHandler}
                        required
                        size="small"
                        margin="normal"
                        autoComplete="off"
                      />
                    </Box>
                  )}
                  <SubmitButton type="submit" disabled={loginLoading}>
                    {!loginLoading && (
                      <>
                        <SubmitButtonIcon className="icon" />

                        <span className="text">
                          {forgotPassword ? "Reset Password" : "Log In"}
                        </span>
                      </>
                    )}
                    {loginLoading && (
                      <ReactLoading
                        type={"spin"}
                        color={"white"}
                        height={20}
                        width={20}
                      />
                    )}
                  </SubmitButton>
                </Form>

                {!forgotPassword && (
                  <p
                    tw="mt-6 text-xs text-gray-600 text-center cursor-pointer hover:underline"
                    onClick={() => setForgotPassword(true)}
                  >
                    Forgot Password ?
                  </p>
                )}

                <p tw="mt-8 text-sm text-gray-600 text-center">
                  Dont have an account?{" "}
                  <a
                    href={signupUrl}
                    tw="border-b border-gray-500 border-dotted"
                  >
                    Sign Up
                  </a>
                </p>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={Images.LoginSideImage} />
            <TextOverlay>
              {/* <h1>
                {" "}
                TXTConnect helped over 3,000 <br /> companies send more than{" "}
                <br /> 1,000,000 SMS
              </h1> */}

              <Heading>
                {" "}
                TXTConnect helped over 3,000 companies send more than 1,000,000
                SMS
              </Heading>
              <div>
                <CustomersLogoStrip>
                  <SectionHeading>Our Clients</SectionHeading>
                  <img src={Images.Client} alt="Our Customers" />
                </CustomersLogoStrip>
              </div>
            </TextOverlay>
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
