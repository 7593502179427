import React, { useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-lines
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import Images from "Images";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import Config from "../Config";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`w-32 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold flex items-center space-x-1 cursor-pointer`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const CustomersLogoStrip = styled.div`
  ${tw`!mt-0 max-w-4xl mx-auto text-center`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider  text-gray-500`}
  }
  // img {
  //   ${tw`w-full`}
  // }
  img {
    ${tw`w-full`}
    background-color: rgba(255, 255, 255, 0.6);
  }
`;

// const SectionContainer = tw(ContentWithVerticalPadding)``;
const SectionHeading = tw.h2`text-3xl  mb-4 tracking-wide text-center`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs cursor-pointer font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 -translate-x-1/2 absolute  top-1/2 left-1/2  whitespace-nowrap z-10`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const OtpInput = tw.input`w-full w-12  text-center py-4 rounded-lg font-medium bg-gray-100 border border-gray-300 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white `;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-900 text-gray-100 w-full h-12 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  //  margin-left: 10px;
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`mx-3`}
  }
`;
// bg-[#e0e0e0]
const Button = styled.button`
  ${tw`mt-5 tracking-wide font-semibold  bg-[#e0e0e0] text-[#666] w-full h-12 rounded-lg hover:bg-[#e0e0e0] transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  margin-right: 20px;
  .icon {
    ${tw`w-6 h-6 -ml-20`}
    margin-left: 15px;
  }
  .text {
    ${tw`mx-3`}
  }
`;
const SubmitButton2 = styled.button`
  ${tw`mt-2 tracking-wide font-semibold bg-primary-900 text-gray-100 w-full h-12 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  //  margin-left: 10px;
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`mx-3`}
  }
`;
// bg-[#e0e0e0]
const Button2 = styled.button`
  ${tw`mt-2 tracking-wide font-semibold  bg-[#e0e0e0] text-[#666] w-full h-12 rounded-lg hover:bg-[#e0e0e0] transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  margin-right: 20px;
  .icon {
    ${tw`w-6 h-6 -ml-20`}
    margin-left: 15px;
  }
  .text {
    ${tw`mx-3`}
  }
`;

const ErrorText = tw.p`w-full text-red-600 text-xs mt-1`;

const OtpInputWrapper = tw.div`w-full flex justify-between items-center`;

const IllustrationContainer = tw.div`sm:rounded-r-lg overflow-hidden bg-purple-100 text-center hidden lg:flex flex-1`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`w-full h-full bg-cover bg-center bg-no-repeat relative `} 
opacity:0.4
`;

const TextOverlay = styled.div`
  ${tw`absolute top-1/2 left-1/2 ml-64 transform -translate-x-1/2 -translate-y-1/2 text-3xl font-bold text-center  `}
  // background-color: rgba(255, 255, 255, 0.3);

  h1 {
    ${tw`text-2xl  p-6`}
  }

  p {
    ${tw`text-lg`}
  }
`;

const StepCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const StepCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ active, completed }) =>
    completed ? "#e42f17" : active ? "#e42f17" : "#e0e0e0"};
  // margin: 0 10px;
  font-size: 14px;
  color: ${({ active, completed }) => (active || completed ? "#fff" : "#666")};
`;
// 4CAF50
const HorizontalLine = styled.div`
  border: 3px solid
    ${({ active, completed }) =>
      completed ? "#e42f17" : active ? "#e42f17" : "#e0e0e0"};
  display: inline-block;
  width: 40px;
  // height: 5px;
`;
// margin: 5px 0;
export default ({
  logoLinkUrl = "/",
  headingText = "Sign Up",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign Up With Google",
      url: "https://google.com",
    },
    {
      iconImageSrc: Images.Fb,
      text: "Sign Up With Facebook",
      url: "https://www.facebook.com",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign Up With Twitter",
      url: "https://twitter.com",
    },
  ],
  submitButtonText = "Sign Up",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "#",
  privacyPolicyUrl = "#",
  signInUrl = "/login",
}) => {
  const firstOTP = useRef(null);
  const secondOTP = useRef(null);
  const thirdOTP = useRef(null);
  const fourthOTP = useRef(null);
  const [mode, setMode] = useState("1");
  const [page, setPage] = useState(1);
  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };
  const [showOtp, setShowOtp] = useState(false);
  const [isPasswordSame, setIsPasswordSame] = useState(true);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  // const [button, SetButton] = usestate(false);
  const [preSteps, setPreSteps] = useState([]);
  const [previousButton, setPreviousButton] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  const renderStepCircleContent = (step) => {
    if (completedSteps.includes(step)) {
      return <FaCheck />;
    } else {
      return step; // or any other desired content
    }
  };
  const renderPreStepCircleContent = (step) => {
    if (preSteps.includes(step)) {
      return step;
    } else {
      return null; // or any other desired content
    }
  };
  const [formVal, setFormVal] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    mode: "",
    socialId: "",
  });
  const [otpVal, setOtpVal] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  const responseGoogle = (res) => {
    // console.log("google", res);
    if (res?.tokenId) {
      setMode("google");
      setFormVal({
        name: res?.profileObj?.givenName,
        email: res?.profileObj?.email,
        mobile: "",
        password: "",
        confirmPassword: "",
        mode: 2,
        socialId: res?.googleId,
      });
    }
  };
  const responseFacebook = (res) => {
    // console.log("fb", res);

    if (res?.id) {
      setMode("facebook");

      setFormVal({
        name: res?.name,
        email: res?.email,
        mobile: "",
        password: "",
        confirmPassword: "",
        mode: 3,
        socialId: res?.id,
      });
    }
  };

  const goBack = () => {
    if (mode) {
      setMode("");
      setShowOtp(false);
      setOtpVal({
        first: "",
        second: "",
        third: "",
        fourth: "",
      });
      setFormVal({
        name: "",
        email: "",
        mobile: "",
        password: "",
        confirmPassword: "",
        mode: "",
        socialId: "",
      });
    }
  };

  const changeHandler = (e) => {
    const { value, name } = e.target;

    if (name == "mobile" && value.length > 9) return;

    setFormVal((prev) => ({ ...prev, [name]: value }));
  };

  const OtpChangeHandler = (e) => {
    const { value, name } = e.target;
    if (value) {
      const length = value.length;
      if (value.length === 1) {
        setOtpVal((prev) => ({ ...prev, [name]: value }));
        if (name == "first") secondOTP.current.focus();
        else if (name == "second") thirdOTP.current.focus();
        else if (name == "third") fourthOTP.current.focus();
      }
    } else {
      setOtpVal((prev) => ({ ...prev, [name]: value }));
      if (name == "fourth") thirdOTP.current.focus();
      else if (name == "third") secondOTP.current.focus();
      else if (name == "second") firstOTP.current.focus();
    }
  };

  console.log({ currentStep, completedSteps });

  const submitHandler = async (e) => {
    e.preventDefault();

    if (currentStep === 1) {
      if (!formVal.name.trim()) {
        return toast.error("Please enter your name");
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(formVal.email)) {
        return toast.error("Please enter a valid email address");
      }
      if (formVal.mobile.length !== 9) {
        return toast.error("Please enter your valid 9-digit phone number");
      }
      const mobileNumber = parseInt(formVal.mobile);

      if (isNaN(mobileNumber)) {
        return toast.error("Please enter a valid numeric phone number");
      }

      setCurrentStep((prevStep) => prevStep + 1); // Move to the next step
      setCompletedSteps([1]);
      setPreSteps([]);
    }

    if (currentStep === 2 && !showOtp) {
      if (formVal.password.length < 8) {
        return toast.error("Password should be at least 8 characters");
      } else if (formVal.password !== formVal.confirmPassword) {
        return toast.error("Password and Confirm Password should be the same");
      }

      setIsPasswordSame(true);
      // setCurrentStep(3);

      await registerUser();
      // return;
    }

    if (currentStep === 3 && showOtp) {
      if (!otpVal.first || !otpVal.second || !otpVal.third || !otpVal.fourth) {
        return toast.error("Please enter OTP");
      }
      // setCompletedSteps((prevCompletedSteps) => [...prevCompletedSteps, 3]); // Mark the third step as completed
      setPreviousButton((prevStep) => prevStep - 1);
      setPreSteps((prevPreSteps) => [...prevPreSteps, 2]);
      await VerifyOtp();
    }
  };

  const registerUser = async () => {
    try {
      if (!registerLoading && !otpLoading) {
        setRegisterLoading(true);
        const res = await axios.post(`${Config.apiUrl}/register`, {
          mode: formVal?.mode || mode,
          password: formVal?.password,
          phone: formVal?.mobile,
          socialId: formVal?.socialId,
          name: formVal?.name,
          email: formVal?.email,
        });

        setShowOtp(true);
        toast.success(res.data.msg || "Success");
        setCurrentStep((prevStep) => prevStep + 1);
        setPreviousButton((prevStep) => prevStep - 1);
        setCompletedSteps((prev) => [...prev, 2]); // Mark the second step as completed
        setPreSteps((prevPreSteps) => [...prevPreSteps, 1]);
        setRegisterLoading(false);
      }
    } catch (error) {
      // console.log("register user error: ", error?.response);
      setRegisterLoading(false);
      toast.error(
        error?.response?.data?.msg ||
          "Something went wrong! please try again later."
      );
    }
  };

  const VerifyOtp = async () => {
    try {
      if (!registerLoading && !otpLoading) {
        setOtpLoading(true);
        const res = await axios.post(`${Config.apiUrl}/checkotp`, {
          phone: formVal?.mobile,
          email: formVal?.email,
          otp: otpVal?.first + otpVal?.second + otpVal?.third + otpVal?.fourth,
        });
        // console.log(res);

        setShowOtp(true);
        // toast.success(res.data.msg || "Success");
        setOtpLoading(false);

        localStorage.setItem("user", JSON.stringify(res?.data));
        window.location = Config.domain + "/cms";
      }
    } catch (error) {
      // console.log("Verify OTP: ", error?.response);
      setOtpLoading(false);
      toast.error(
        error?.response?.data?.msg ||
          "Something went wrong! please try again later."
      );
    }
  };

  return (
    <AnimationRevealPage>
      <Container style={{ backgroundImage: ` url("/images/sign-up-bg.png") ` }}>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={Images.Logo} />
            </LogoLink>
            <MainContent>
              <Heading>
                <p>{headingText}</p>
              </Heading>
              {mode && (
                <StepCount>
                  <StepCircle
                    active={currentStep === 1}
                    completed={completedSteps?.includes(1)}
                  >
                    {renderStepCircleContent(1)}
                  </StepCircle>
                  <HorizontalLine
                    active={currentStep >= 2}
                    completed={completedSteps?.includes(1)}
                  />
                  <StepCircle
                    active={currentStep === 2}
                    completed={completedSteps?.includes(2)}
                  >
                    {renderStepCircleContent(2)}
                  </StepCircle>
                  <HorizontalLine
                    active={currentStep >= 3}
                    completed={completedSteps.includes(2)}
                  />
                  <StepCircle
                    active={currentStep === 3}
                    completed={completedSteps.includes(3)}
                  >
                    {renderStepCircleContent(3)}
                  </StepCircle>
                </StepCount>
              )}
              <FormContainer>
                {mode && (
                  <Form method="POST">
                    {!showOtp && currentStep == 1 && (
                      <>
                        <Box
                          component="form"
                          sx={{
                            "& > :not(style)": {
                              // my: 1,
                              width: "100%",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#C4C5C5",
                              },

                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#C4C5C5",
                              },
                            "& .MuiFilledInput-root:hover": {
                              borderColor: "#C4C5C5",
                            },
                            "& .MuiFilledInput-root.Mui-focused": {
                              borderColor: "#C4C5C5",
                            },
                            "& .MuiInputBase-root:hover": {
                              borderColor: "#C4C5C5",
                            },
                            "& .MuiInputBase-root.Mui-focused": {
                              borderColor: "#C4C5C5",
                            },
                            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                              color: "#374151",
                            },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            id="name"
                            label="Name"
                            type="text"
                            value={formVal.name}
                            name="name"
                            onChange={changeHandler}
                            required
                            size="small"
                            margin="normal"
                            // focused
                          />

                          <TextField
                            label="Email"
                            type="text"
                            id="email"
                            value={formVal.email}
                            name="email"
                            onChange={changeHandler}
                            required
                            size="small"
                            margin="normal"
                            // focused
                          />

                          <TextField
                            label="Mobile"
                            type="number"
                            id="mobile"
                            value={formVal.mobile}
                            name="mobile"
                            onChange={changeHandler}
                            required
                            size="small"
                            margin="normal"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +233
                                </InputAdornment>
                              ),
                            }}
                          />
                          {/* <p
                            style={{
                              fontSize: "0.7rem",
                              color: "#888",
                              
                            }}
                          >
                            NOTE: Enter your 9-digit mobile number without 0 or
                            country code.
                          </p> */}
                        </Box>

                        <SubmitButton
                          type="submit"
                          disabled={registerLoading || otpLoading}
                          // onClick={submitHandler}
                          onClick={(e) => {
                            submitHandler(e);
                            setPreviousButton(false);
                          }}
                        >
                          {!registerLoading && !otpLoading && (
                            <span className="text">
                              {showOtp ? "Confirm OTP" : "Next"}
                            </span>
                          )}
                          {!showOtp && !registerLoading && (
                            <IoIosArrowForward className="w-4 h-4 text-white" />
                          )}
                          {(registerLoading || otpLoading) && (
                            <ReactLoading
                              type={"spin"}
                              color={"white"}
                              height={20}
                              width={20}
                            />
                          )}
                        </SubmitButton>
                      </>
                    )}

                    {!showOtp && currentStep == 2 && (
                      <>
                        <Box
                          component="form"
                          sx={{
                            "& > :not(style)": {
                              // my: 1,
                              width: "100%",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#C4C5C5",
                              },

                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#C4C5C5",
                              },
                            "& .MuiFilledInput-root:hover": {
                              borderColor: "#C4C5C5",
                            },
                            "& .MuiFilledInput-root.Mui-focused": {
                              borderColor: "#C4C5C5",
                            },
                            "& .MuiInputBase-root:hover": {
                              borderColor: "#C4C5C5",
                            },
                            "& .MuiInputBase-root.Mui-focused": {
                              borderColor: "#C4C5C5",
                            },
                            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                              color: "#374151",
                            },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            label="Password"
                            type="password"
                            id="password"
                            value={formVal.password}
                            name="password"
                            onChange={changeHandler}
                            required
                            size="small"
                            margin="normal"
                            // helperText="*Password should be atleast 8 characters"
                          />

                          <TextField
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            value={formVal.confirmPassword}
                            name="confirmPassword"
                            onChange={changeHandler}
                            required
                            size="small"
                            margin="normal"
                          />
                        </Box>

                        <p
                          style={{
                            fontSize: 10,
                            color: "gray",
                            // marginTop: 5,
                            marginBottom: 24,
                          }}
                        >
                          *Password should be atleast 8 characters
                        </p>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {currentStep > 1 && (
                            <>
                              <Button2
                                onClick={() => {
                                  setCurrentStep(currentStep - 1);

                                  setCompletedSteps((prev) =>
                                    prev.filter((step) => step != 1)
                                  );
                                  setPreSteps((prevPreSteps) => [
                                    ...prevPreSteps,
                                    currentStep - 1,
                                  ]);
                                  setPreviousButton(true);
                                }}
                                type="button"
                              >
                                <IoIosArrowBack className="w-4 h-4 ml-6 text-white " />
                                <span className="text">Previous</span>
                              </Button2>

                              <SubmitButton2
                                type="submit"
                                disabled={registerLoading || otpLoading}
                                onClick={(e) => {
                                  submitHandler(e);
                                  setPreviousButton(false);
                                }}
                              >
                                {!registerLoading && !otpLoading && (
                                  <span className="text">
                                    {showOtp ? "Confirm OTP" : "Next"}
                                  </span>
                                )}
                                {!showOtp && !registerLoading && (
                                  <IoIosArrowForward className="w-4 h-4 text-white" />
                                )}
                                {(registerLoading || otpLoading) && (
                                  <ReactLoading
                                    type={"spin"}
                                    color={"white"}
                                    height={20}
                                    width={20}
                                  />
                                )}
                              </SubmitButton2>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {showOtp && (
                      <>
                        <OtpInputWrapper>
                          <OtpInput
                            type="number"
                            maxLength={1}
                            name="first"
                            value={otpVal.first}
                            required
                            onChange={OtpChangeHandler}
                            ref={firstOTP}
                          />
                          <OtpInput
                            type="number"
                            maxLength={1}
                            name="second"
                            value={otpVal.second}
                            required
                            onChange={OtpChangeHandler}
                            ref={secondOTP}
                          />
                          <OtpInput
                            type="number"
                            maxLength={1}
                            name="third"
                            value={otpVal.third}
                            required
                            onChange={OtpChangeHandler}
                            ref={thirdOTP}
                          />
                          <OtpInput
                            type="number"
                            maxLength={1}
                            name="fourth"
                            value={otpVal.fourth}
                            required
                            onChange={OtpChangeHandler}
                            ref={fourthOTP}
                          />
                        </OtpInputWrapper>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {currentStep > 1 && (
                            <>
                              <Button
                                type="button"
                                onClick={() => {
                                  setCurrentStep((prevStep) => prevStep - 1);

                                  setCompletedSteps((prev) =>
                                    prev.filter((step) => step != 2)
                                  );
                                  setPreSteps((prevPreSteps) => [
                                    ...prevPreSteps,
                                    currentStep - 1,
                                  ]);
                                  setShowOtp(false);
                                  setPreviousButton(true);
                                }}
                              >
                                <IoIosArrowBack className="w-4 h-4 text-white" />
                                <span className="text">Previous</span>
                              </Button>
                            </>
                          )}
                          <SubmitButton
                            type="submit"
                            disabled={registerLoading || otpLoading}
                            // onClick={submitHandler}
                            onClick={(e) => {
                              submitHandler(e);
                              setPreviousButton(false);
                            }}
                          >
                            {!registerLoading && !otpLoading && (
                              <span className="text">
                                {showOtp ? "Confirm OTP" : "Next"}
                              </span>
                            )}
                            {!showOtp && !registerLoading && (
                              <IoIosArrowForward className="w-4 h-4 text-white" />
                            )}
                            {(registerLoading || otpLoading) && (
                              <ReactLoading
                                type={"spin"}
                                color={"white"}
                                height={20}
                                width={20}
                              />
                            )}
                          </SubmitButton>
                        </div>
                      </>
                    )}

                    <p tw="mt-6 text-xs text-gray-600 text-center">
                      I agree to abide by{" "}
                      <a
                        href={tosUrl}
                        tw="border-b border-gray-500 border-dotted"
                      >
                        Terms of Service
                      </a>{" "}
                      and its{" "}
                      <a
                        href={privacyPolicyUrl}
                        tw="border-b border-gray-500 border-dotted"
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </Form>
                )}

                {!mode && (
                  <SocialButtonsContainer>
                    <SocialButton
                      onClick={() => setMode(1)}
                      style={{ minWidth: 250 }}
                    >
                      <span className="iconContainer">
                        <img src={Images.Gmail} className="icon" alt="" />
                      </span>
                      <span className="text">Sign Up with Email</span>
                    </SocialButton>
                  </SocialButtonsContainer>
                )}
                <p tw="mt-8 text-sm text-gray-600 text-center">
                  Already have an account?{" "}
                  <a
                    href={signInUrl}
                    tw="border-b border-gray-500 border-dotted"
                  >
                    Log In
                  </a>
                </p>
              </FormContainer>
            </MainContent>
          </MainContainer>

          <IllustrationContainer>
            <IllustrationImage imageSrc={Images.SignUpSideImage} />
            <TextOverlay>
              <h1>
                TXTConnect helped over 3,000 <br /> companies send more than{" "}
                <br /> 1,000,000 SMS
              </h1>

              <div>
                <CustomersLogoStrip>
                  <SectionHeading>Our Clients</SectionHeading>
                  <img src={Images.Client} alt="Our Customers" />
                </CustomersLogoStrip>
              </div>
            </TextOverlay>
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
