import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

// const PlanDurationSwitcher = tw.div`block w-full sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
// const SwitchButton = styled.button`
//   ${tw`w-2/3 sm:w-40 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
//   ${(props) => props.active && tw`bg-primary-500 text-gray-100`}
// `;

const PlanDurationSwitcher = styled.div`
  ${tw`flex w-full sm:w-auto border-2 rounded-full px-1 py-1 mt-8`}
  flex-wrap: wrap;
`;

const SwitchButton = styled.button`
  ${tw`flex-1 sm:flex-none sm:w-40 px-2 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${(props) => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-semibold text-xl text-gray-600`}
  }
  .price {
    ${tw`font-bold text-3xl sm:text-3xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;

const Plan = styled.div`
  ${tw`w-3/4 max-w-80 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  &:hover ${PlanHeader} {
    background-color: #e42f17;
    color: #ffffff;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  &:hover .name {
    color: #ffffff;
  }

  ${(props) =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanFeatures = styled.div`
  ${tw`flex w-full flex-col -mx-3 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 text-xl first:mt-0 font-bold text-gray-700`}
  }
  .sub_title {
    ${tw`mt-3 text-2xl font-semibold text-gray-600 text-center`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "Discover our cost-effective packages for bulk SMS with TXTConnect. Our pricing is among the lowest and is designed to meet the needs of businesses",
  plans = null,
  primaryButtonText = "Buy Now",
  planDurations = [
    {
      text: "one_month",
      switcherText: "1 Month",
    },
    {
      text: "two_months",
      switcherText: "2 Months",
    },
    {
      text: "three_months",
      switcherText: "3 Months",
    },
    {
      text: "no_expiry",
      switcherText: "No Expiry",
    },
  ],
}) => {
  //state to keep the packages
  const [packages, setPackages] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = "https://txtconnect.net/api/getPackage";
        const options = {
          method: "GET",
          headers: {
            for: "web",
          },
        };

        const response = await fetch(url, options);
        const plansData = await response.json();

        // Transform the data
        const bronze_prices = [];
        const silver_prices = [];
        const gold_prices = [];
        const platinum_prices = [];
        const durations = [];

        //sms credits
        const bronzeCredits = [];
        const silverCredits = [];
        const goldCredits = [];
        const platinumCredits = [];
        plansData.map((plan) => {
          durations.push(plan.name);
          const packs = plan.packs;
          packs.map((item) => {
            if (item.name === "Bronze") {
              bronze_prices.push(item.price.toString());
              bronzeCredits.push(item.unit);
            }
            if (item.name === "Silver") {
              silver_prices.push(item.price.toString());
              silverCredits.push(item.unit);

            }
            if (item.name === "Gold") {
              gold_prices.push(item.price.toString());
              goldCredits.push(item.unit);
            }
            if (item.name === "Platinum") {
              platinum_prices.push(item.price.toString());
              platinumCredits.push(item.unit);
            }
          });
        });
        const defaultPlans = [
          {
            name: "Bronze",
            durationPrices: bronze_prices,
            mainFeature: durations,
            // features: ["1,300 SMS"],
            // features: [`${plansData[0].packs[0].unit.toString()} SMS`],
            features: bronzeCredits,
          },
          {
            name: "Silver",
            durationPrices: silver_prices,
            mainFeature: durations,
            // features: ["6,050 SMS"],
            // features: [`${plansData[0].packs[1].unit.toString()} SMS`],
            features: silverCredits,
            featured: true,
          },
          {
            name: "Gold",
            durationPrices: gold_prices,
            mainFeature: durations,
            // features: ["55,000 SMS"],
            // features: [`${plansData[0].packs[2].unit.toString()} SMS`],
            features: goldCredits,

            featured: true,
          },
          {
            name: "Platinum",
            durationPrices: platinum_prices,
            mainFeature: durations,
            // features: ["100,000 SMS"],
            features: platinumCredits,
            featured: true,
          },
        ];

        // Set the transformed data in state
        setPackages(defaultPlans);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // Call the fetchData function
    fetchData();
  }, []);

  if (!plans) plans = packages;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  return (
    <Container id="pricing">
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          <PlanDurationSwitcher>
            {planDurations.map((planDuration, index) => (
              <SwitchButton
                active={activeDurationIndex === index}
                key={index}
                onClick={() => setActiveDurationIndex(index)}
              >
                {planDuration.switcherText}
              </SwitchButton>
            ))}
          </PlanDurationSwitcher>
        </HeaderContainer>
        <PlansContainer>
          {packages &&
            plans.map((plan, index) => (
              <Plan key={index} featured={plan.featured}>
                <PlanHeader>
                  <span className="priceAndDuration">
                    <span className="price">{plan.name}</span>
                  </span>
                  <span className="name">{`GHS ${parseFloat(
                      plan.durationPrices[activeDurationIndex]
                    ).toFixed(2)}`}</span>
                </PlanHeader>
                <PlanFeatures>
                  {/* {plan.features.map((feature, index) => ( */}
                    <>
                      <span className="sub_title">SMS Package</span>
                      <span key={index} className="feature">
                        {`${plan.features[activeDurationIndex]} units`}
                      </span>
                    </>
                  {/* ))} */}
                </PlanFeatures>
                <PlanAction>
                  <BuyNowButton onClick={() => (window.location = "/login")}>
                    {primaryButtonText}
                  </BuyNowButton>
                </PlanAction>
              </Plan>
            ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
