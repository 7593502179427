import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import Images from "Images";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-2xl mx-auto py-20 sm:py-12 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center `;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
// const Image = styled.div((props) => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded md:w-1/2  flex-shrink-0 h-80 md:h-128 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
// ]);
// const Image = styled.div((props) => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded md:w-1/2   flex-shrink-0 h-80 md:h-96 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
// ]);
// const Details = tw.div`mt-4 md:mt-0 md:w-1/2 mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-5/12 flex-shrink-0 h-80 md:h-96  bg-no-repeat bg-contain bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-12  lg:my-0`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-xl mx-4 sm:mx-8 md:mx-4 lg:mx-12`;
const Subtitle = tw.div`font-bold tracking-wide `;
const Title = tw.h4`text-2xl sm:text-2xl lg:text-3xl font-bold text-gray-900 leading-tight`;
const Description = tw.p`mt-2 text-base font-medium `;
const Link = tw.a`inline-block mt-4 text-sm   cursor-pointer text-primary-500 rounded-md hover:bg-primary-500 hover:text-white transition duration-300 border border-primary-500 py-2 px-4 hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: Images.SmsDel1,
      subtitle: "Identify and engage more of your on-site browsers",
      title: "Engage (don’t annoy) your customers",
      description:
        "Send messages to subscribers when they’re most likely to buy and least likely to opt-out. Use intelligent tools such as Smart Sending, Quiet Hours, and more to make sure you’re sensitive to time zones and never interrupting.",
      url: "/contact",
      //   learn: "Learn About Audience Manager",
    },

    {
      imageSrc: Images.SmsDel2,
      subtitle: "Easily create personalized campaigns that convert",
      title: "Reach customers wherever they are",
      description:
        "The Attentive Deliverability Network partners with multiple aggregators and 80+ carriers to make sure you’re able to connect with customers around the world and your messages are always delivered—even during high traffic periods and peak holidays.",
      url: "/contact",
      //   learn: "Learn About Conciegre",
    },

    {
      imageSrc: Images.SmsDel3,
      subtitle:
        "An industry-first, unified composer for email and SMS that makes orchestration and retargeting fast and easy",
      title: "Turn SMS into your own, branded channel",
      description:
        "Drive more revenue by becoming a trusted contact your customers want to hear from. Always reach the top of the inbox with features like contact cards that increase engagement and never leave you stuck on ‘read.’",
      url: "/contact",
      // learn: "Watch the Video",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        {/* <HeadingInfoContainer>
          <HeadingTitle>Designed to maximize conversions</HeadingTitle>

          <HeadingDescription>
            A more powerful, unified, and innovative messaging platform that
            gets results
          </HeadingDescription>
        </HeadingInfoContainer> */}

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Title>{card.title}</Title>
                {/* <Subtitle>{card.subtitle}</Subtitle> */}
                <Description>{card.description}</Description>
                {card.learn && <Link href={card.url}>{card.learn}</Link>}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
