import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";
import Images from "Images";
import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap  max-w-screen-xl mx-auto pb-20 md:pb-24`}
`;
// const Heading = tw.h2`w-full text-2xl lg:mt-0 mt-12 sm:text-4xl md:text-5xl font-bold text-black tracking-wide text-center  leading-none`;
const SubHeading = tw.h2`mt-2 w-full text-center font-medium text-sm md:text-base lg:text-lg text-black `;
const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center h-[30rem] pb-12 bg-white  border-2 border border-gray-200 rounded-xl mt-12 relative`}
  .imageContainer {
    ${tw` border-b text-center py-4 w-full flex justify-center  flex-shrink-0 relative`}
    img {
      ${tw`w-16 h-4`}
    }
  }
  .padding {
    ${tw`pl-4 pr-16 `}
  }
  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw` font-medium text-black mt-4 text-sm leading-loose bg-gray-200 rounded-lg px-2 pb-4 pt-2`}
  }
`;

const InputField = styled.div`
  ${tw`absolute bottom-0 left-0 flex flex-col items-center mt-8 mx-auto max-w-xs px-2`}
  input {
    ${tw`w-full border rounded-full h-[2.25rem] px-4 focus:outline-none`}
  }
  .input-group {
    ${tw`w-full flex rounded-full overflow-hidden`}
    button {
      ${tw`bg-primary-500 text-white ml-1 px-3 h-[2.25rem] rounded-full`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw(
  ContainerBase
)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(
  SectionDescription
)`text-gray-400 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`;
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`;
const StatKey = tw.div`text-xl font-medium`;
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: Images.Logo,
      description:
        "Enjoy 20% off your next purchase! Use code SMS20 at checkout. ",
    },
    {
      imageSrc: Images.Logo,
      description:
        "Get a FREE pizza with any order of $20 or more! Mention this SMS. ",
    },
    {
      imageSrc: Images.Logo,
      description:
        "Shop our latest fashion arrivals and get 15% off! Use code STYLE15. ",
    },
    {
      imageSrc: Images.Logo,
      description:
        "Upgrade your tech with our biggest sale of the year! Save up to 50%. ",
    },
    // {
    //   imageSrc: Images.Logo,
    //   description:
    //     "Furnish your dream home with our amazing furniture deals! Save 30% or more. ",
    // },
  ];

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          <Heading>Vouchers</Heading>
          <Description>Special Offers for You</Description>
        </HeadingContainer>
        <ThreeColumnContainer>
          {/* <Heading>Vouchers</Heading>
        <SubHeading>Special Offers for You</SubHeading> */}
          {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <span className="imageContainer">
                  <img src={card.imageSrc || defaultCardImage} alt="" />
                </span>
                {/* <span className="textContainer"> */}
                {/* <span className="title">{card.title || "Fully Secure"}</span> */}
                <span className="padding">
                  <p className="description">
                    {card.description ||
                      "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                  </p>
                </span>
                {/* </span> */}

                <InputField>
                  <div className="input-group">
                    <input disabled />
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="22" y1="2" x2="11" y2="13" />
                        <polygon points="22 2 15 22 11 13 2 9 22 2" />
                      </svg>
                    </button>
                  </div>
                </InputField>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
        <DecoratorBlob />
        {/* <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer> */}
      </ContentWithPaddingXl>
    </Container>
  );
};
