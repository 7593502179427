import React, { useState, useEffect } from "react";
import MarketPlace from "components/integration/MarketPlace";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Stats from "components/integration/Stats";
import Strategy from "components/integration/Strategy";

import Feature from "components/integration/Feature";
import tw from "twin.macro";
import SuccessStory from "components/integration/SuccessStory";
import TrendsIntegration from "components/integration/TrendsIntegration";
import Footer from "components/footers/MiniCenteredFooter";
import styled from "styled-components";
import Images from "Images";
import Header from "components/headers/light.js";
import { SectionHeading as HeadingTitle } from "components/misc/Headings.js";

const CustomersLogoStrip = styled.div`
  ${tw` mt-4  max-w-4xl mx-auto text-center`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`w-full`}
  }
`;

const Link = tw.a`inline-block mt-4 text-sm   cursor-pointer text-primary-500 rounded-md hover:bg-primary-500 hover:text-white transition duration-300 border border-primary-500 py-2 px-4 hover:border-primary-500`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const HeadingSubTitle = tw.h2`text-xl mt-12 lg:mt-4  text-gray-700  tracking-wide font-normal text-center leading-none`;
const MaxWidth = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;
export default ({}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeNavLink, setActiveNavLink] = useState(""); // Store active nav link state

  // Function to handle Product link click in Footer and update Navbar state
  const handleProductLinkClick = () => {
    setActiveNavLink("Products"); // Set active link in Navbar
    setIsSubMenuOpen(true); // Open the Products dropdown
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [user, setUser] = useState(false);
  return (
    <AnimationRevealPage>
      {/* <MaxWidth> */}
      <Header
        roundedHeaderButton={true}
        user={user}
        isSubMenuOpen={isSubMenuOpen}
        activeNavLink={activeNavLink}
        setActiveNavLink={setActiveNavLink}
        setIsSubMenuOpen={setIsSubMenuOpen}
      />
      <MarketPlace />
      {/* <div>
        <HeadingInfoContainer>
          <HeadingTitle>Check out some Customers using our API</HeadingTitle>
          <HeadingSubTitle>
            Flexible, easy integrations so you can focus on <br /> sending
            personalized messages that drive business growth.
          </HeadingSubTitle>
        </HeadingInfoContainer>
        <CustomersLogoStrip>
          <img src={Images.Integration} alt="Our Customers" />
          <Link href="/contact">View our partner directory</Link>
        </CustomersLogoStrip>
      </div> */}
      <Feature />
      {/* <Stats /> */}
      {/* <TrendsIntegration /> */}

      {/* <SuccessStory /> */}

      {/* <Strategy /> */}
      <Footer onProductLinkClick={handleProductLinkClick} />
      {/* </MaxWidth> */}
    </AnimationRevealPage>
  );
};
