import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import { BsShieldFillCheck } from "react-icons/bs";
import { HiUsers } from "react-icons/hi";
import { AiFillMessage } from "react-icons/ai";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw.h2`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-white leading-tight`;
const Description = tw.p`my-5 lg:my-8 text-base  text-gray-300 max-w-xl`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 max-w-xs`}
`;
const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-8 text-sm sm:text-base sm:mt-10 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 text-primary-500 hover:text-white hover:bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;
const Card = styled.div`
  ${tw`flex flex-col items-center  text-center  h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;
const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-md`,
]);

export default ({
  cards = null,
  heading = "Your customers want to converse with your brand throughout their journey",
  subheading = "",

  primaryButtonUrl = "/contact",
  buttonRounded = true,
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;

  const defaultCards = [
    {
      icon: <HiUsers tw="w-6 h-6 text-gray-800" />,
      title: "88%",
      description: "want to ask specific product questions",
    },
    {
      icon: <BsShieldFillCheck tw="w-6 h-6 text-gray-800" />,
      title: "87%",
      description: "want help resolving issues with cart/checkout",
    },

    {
      icon: <AiFillMessage tw="w-6 h-6 text-gray-800" />,
      title: "70%",
      description: "need product recommendations",
    },
    {
      icon: <AiFillMessage tw="w-6 h-6 text-gray-800" />,
      title: "87%%",
      description: "want to know what’s coming next",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <div tw="text-center">
          <Heading>{heading}</Heading>
        </div>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer  mx-auto ">{card.icon}</span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
        <PrimaryAction
          buttonRounded={buttonRounded}
          as="a"
          href={primaryButtonUrl}
        >
          Discover More
        </PrimaryAction>
      </ThreeColumnContainer>
    </Container>
  );
};
