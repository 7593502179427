import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import Images from "Images.js";
import {
  MdTextsms,
  MdEmail,
  MdManageAccounts,
  MdIntegrationInstructions,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdDesktopWindows,
} from "react-icons/md";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { FaConciergeBell, FaBookOpen } from "react-icons/fa";
import { AiFillSignal, AiFillPieChart, AiOutlineMobile } from "react-icons/ai";
import {
  FaWandMagicSparkles,
  FaHeadphones,
  FaSackDollar,
} from "react-icons/fa6";
import { TbWorldSearch } from "react-icons/tb";
import { RiShieldUserFill } from "react-icons/ri";
import { HiMiniArchiveBoxArrowDown } from "react-icons/hi2";
import { BsFillSuitHeartFill } from "react-icons/bs";
import { PiNotepadFill } from "react-icons/pi";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { BiSolidUserVoice } from "react-icons/bi";
// const Header = tw.header`
//   flex justify-between items-center
//   max-w-screen-xl mx-auto
// `;
const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Header = styled.header`
  ${tw`flex justify-between items-center max-w-screen-xl mx-auto transition-all duration-300`}
`;
// const WideHead = styled.div`
//   ${tw` items-center w-full `}
//   ${({ isSubMenuOpen }) => (isSubMenuOpen ? tw`bg-gray-100` : tw`bg-white`)}
// `;

export const NavLinks = tw.div`inline-block`;
export const NavLinkss = tw.div`lg:inline-block text-lg  lg:text-sm  lg:my-0
font-semibold tracking-wide transition duration-300
 border-b-2 border-transparent cursor-pointer`;
/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 cursor-pointer
`;

export const SubNavLink = tw.a`
  text-sm  lg:text-sm 
  font-semibold tracking-wide transition duration-300 flex
  pt-4 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 cursor-pointer 
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-32`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white overflow-y-auto`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;
const Link = tw.a`inline-block  text-sm underline  cursor-pointer text-primary-500  `;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
  user,
  // isFooterProductClicked,
  isSubMenuOpen,
  activeNavLink,
  setActiveNavLink,
  setIsSubMenuOpen,
}) => {
  const [isSubMenuOpenBlog, setIsSubMenuOpenBlog] = useState(false);

  const handleMouseEnter = () => {
    setIsSubMenuOpenBlog(true);
  };

  const handleMouseLeave = () => {
    setIsSubMenuOpenBlog(false);
  };

  const handleProductMouseEnter = () => {
    setIsSubMenuOpen(true);
  };

  const handleProductMouseLeave = () => {
    setIsSubMenuOpen(false);
  };
  // const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const closeSubMenus = () => {
    setIsSubMenuOpenBlog(false);
    setIsSubMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        closeSubMenus();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (
  //       !event.target.closest(".product-dropdown") &&
  //       !event.target.closest(".resources-dropdown")
  //     ) {
  //       closeSubMenus();
  //     }
  //   };

  //   document.addEventListener("click", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, []);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const productDropdown = event.target.closest(".product-dropdown");
      const resourcesDropdown = event.target.closest(".resources-dropdown");

      if (
        !productDropdown &&
        !resourcesDropdown &&
        !event.target.matches(".footer-product")
      ) {
        closeSubMenus();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleSubMenuBlog = () => {
    setIsSubMenuOpenBlog(!isSubMenuOpenBlog);
    setIsSubMenuOpen(false);
  };
  const toggleSubMenuProduct = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
    setIsSubMenuOpenBlog(false);
  };

  const defaultLinks = [
    <NavLinks key={1}>
      {/* <NavLink href="/products/sms-broadcast">Products</NavLink> */}
      <NavLinkss>
        <NavLinkWithSubMenu
          isOpen={isSubMenuOpen}
          toggleSubMenu={toggleSubMenuProduct}
          // handleProductMouseEnter={handleProductMouseEnter}
          // handleProductMouseLeave={handleProductMouseLeave}
        >
          Products{" "}
        </NavLinkWithSubMenu>
      </NavLinkss>
      <NavLinkss>
        <NavLinkWithSubMenuBlog
          isOpen={isSubMenuOpenBlog}
          toggleSubMenu={toggleSubMenuBlog}
          // handleMouseEnter={handleMouseEnter}
          // handleMouseLeave={handleMouseLeave}
        >
          Resources{" "}
        </NavLinkWithSubMenuBlog>
      </NavLinkss>
      <NavLink href="/#pricing">Pricing</NavLink>
      <NavLink href="/contact">Contact Us</NavLink>
      <NavLink href="/about">About Us</NavLink>

      <NavLink href="/login" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink
        css={roundedHeaderButton && tw`rounded-full`}
        href="/sign-up"
      >
        Sign Up
      </PrimaryLink>
    </NavLinks>,
  ];

  const notLoginLinks = [
    <NavLinks key={1}>
      <NavLink href="/login" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink
        css={roundedHeaderButton && tw`rounded-full`}
        href="/sign-up"
      >
        Sign Up
      </PrimaryLink>
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={Images.Logo} alt="logo" />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  // links = user ? links || defaultLinks : notLoginLinks;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      {/* <Header
      className={className || "header-light"}
      isSubMenuOpen={(isSubMenuOpenBlog, isSubMenuOpen)}
    > */}
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {links}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};

const NavLinkWithSubMenuBlog = ({
  href,
  children,
  isOpen,
  toggleSubMenu,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  return (
    <div
      style={{ display: "flex" }}
      className="resources-dropdown"
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
    >
      <NavLink onClick={toggleSubMenu}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {children}{" "}
          {isOpen ? (
            <MdKeyboardArrowUp tw="ml-1 mt-1" />
          ) : (
            <MdKeyboardArrowDown tw="ml-1 mt-1" />
          )}
        </div>
      </NavLink>
      {isOpen && (
        <>
          <WideDiv>
            <SubMenu>
              <SubMenuColumn>
                <div>
                  <Label>TEMPLATES</Label>
                  <ul>
                    {/* <SubNavLink
                      onClick={() => (window.location.href = "/messaging")}
                    >
                      <Icon>
                        <PiNotepadFill />
                      </Icon>
                      Blog
                    </SubNavLink>
                    <SubHeading>
                      Strategies, trends, and insights to help you become an
                      industry expert
                    </SubHeading> */}
                    <SubNavLink
                      onClick={() => (window.location.href = "/sms-we-love")}
                    >
                      <Icon>
                        <BsFillSuitHeartFill />
                      </Icon>
                      SMS We Love
                    </SubNavLink>
                    <SubHeading>
                      Real SMS examples to inspire your next campaign
                    </SubHeading>
                    <Label tw=" lg:!mt-4">CASE STUDY</Label>
                    <SubNavLink
                      onClick={() => (window.location.href = "/case-study")}
                    >
                      <Icon>
                        <AiOutlineMobile />
                      </Icon>
                      Case Studies
                    </SubNavLink>
                    <SubHeading>
                      Explore proven strtegies and results from innovative
                      brands
                    </SubHeading>

                    {/* <SubNavLink
                    onClick={() => (window.location.href = "/messaging")}
                  >
                    <Icon>
                      <MdDesktopWindows />
                    </Icon>
                    Webinars
                  </SubNavLink>
                  <SubHeading>
                    Live and on-demand recordings to keep learning
                  </SubHeading> */}
                  </ul>
                </div>
              </SubMenuColumn>

              <SubMenuColumn>
                <div>
                  <Label>HELP</Label>
                  <ul>
                    {/* <SubNavLink
                    onClick={() => (window.location.href = "/messaging")}
                  >
                    <Icon>
                      <MdDesktopWindows />
                    </Icon>
                    Webinars
                  </SubNavLink>
                  <SubHeading>
                    Live and on-demand recordings to keep learning
                  </SubHeading> */}

                    <SubNavLink
                      onClick={() => (window.location.href = "/guide")}
                    >
                      <Icon>
                        <FaBookOpen />
                      </Icon>
                      Guides
                    </SubNavLink>
                    <SubHeading>
                      Text Messaging industry insights to help you become an SMS
                      expert
                    </SubHeading>

                    <SubNavLink
                      onClick={() => (window.location.href = "/help")}
                    >
                      <Icon>
                        <FaHeadphones />
                      </Icon>
                      Help Center
                    </SubNavLink>
                    <SubHeading>
                      Check out our how to guides, webinar, and more
                    </SubHeading>
                  </ul>
                </div>
              </SubMenuColumn>

              <SubMenuColumn>
                <div>
                  <Label>TOOL</Label>
                  <ul>
                    {/* <SubNavLink
                    onClick={() => (window.location.href = "/messaging")}
                  >
                    <Icon>
                      <MdDesktopWindows />
                    </Icon>
                    Webinars
                  </SubNavLink>
                  <SubHeading>
                    Live and on-demand recordings to keep learning
                  </SubHeading> */}

                    <SubNavLink
                      onClick={() => (window.location.href = "/revenue")}
                    >
                      <Icon>
                        {" "}
                        <FaSackDollar />
                      </Icon>
                      Revenue Calculator
                    </SubNavLink>
                    <SubHeading>
                      Instantly calculate your projected revenue and subscriber
                      growth
                    </SubHeading>
                  </ul>
                </div>
              </SubMenuColumn>
              <SubMenuColumns tw="hidden lg:block ">
                <Card onClick={() => (window.location.href = "/contact")}>
                  <PaddingCard>
                    {/* <Subheading tw="mb-2">Blog</Subheading> */}
                    <img src={Images.Blog} tw=" w-144" />
                    <Heading tw="my-4">
                      Engage Audience, Boost Conversions
                    </Heading>
                    {/* <Link>Learn More</Link> */}
                  </PaddingCard>
                </Card>
              </SubMenuColumns>

              {/* <SubMenuColumn tw="hidden lg:block">
                <div> */}
              {/* <ResoCard
                  onClick={() => (window.location.href = "/contact")}
                  >
                    <PaddingCard>
                      <Heading tw="mb-2 flex flex-row font-semibold ">
                        <img src={Images.Hub} tw="mr-2" />
                        Resources Hub
                      </Heading>
                      <Subheading tw="text-black font-normal">
                        Discover the latest tools, guides, and marketing tips.
                      </Subheading>
                      <img src={Images.Reso} tw="mt-16 w-96" />
                    </PaddingCard>
                  </ResoCard> */}
              {/* </div>
              </SubMenuColumn> */}
            </SubMenu>
          </WideDiv>
        </>
      )}
    </div>
  );
};

const NavLinkWithSubMenu = ({
  href,
  children,
  isOpen,
  toggleSubMenu,
  handleProductMouseEnter,
  handleProductMouseLeave,
}) => {
  return (
    <div
      style={{ display: "flex" }}
      className="product-dropdown"
      // onMouseEnter={handleProductMouseEnter}
      // onMouseLeave={handleProductMouseLeave}
    >
      {/* <MaxWidth> */}
      <NavLink onClick={toggleSubMenu}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {children}{" "}
          {isOpen ? (
            <MdKeyboardArrowUp tw="ml-1 mt-1" />
          ) : (
            <MdKeyboardArrowDown tw="ml-1 mt-1" />
          )}
        </div>
      </NavLink>
      {isOpen && (
        <>
          {/* <MaxWidth> */}

          <WideDiv>
            {/* <Heading>Products</Heading> */}
            <SubMenu>
              <SubMenuColumn>
                <div>
                  <Label>MESSAGING</Label>
                  <ul>
                    <SubNavLink
                      onClick={() => (window.location.href = "/messaging")}
                    >
                      <Icon>
                        <MdTextsms />
                      </Icon>
                      Bulk SMS
                    </SubNavLink>
                    <SubHeading>
                      Engage customers with text messages that drive loyalty and
                      revenue
                    </SubHeading>
                    {/* <SubNavLink
                  onClick={() => (window.location.href = "/sms-deliverability")}
                >
                  <Icon>
                    <PiPaperPlaneRightFill />
                  </Icon>
                  Deliverability
                </SubNavLink>
                <SubHeading>
                  Send messages knowing they'll be delivered, even during peak
                  periods
                </SubHeading> */}
                    {/*
                    <SubNavLink
                      onClick={() => (window.location.href = "/concierge")}
                    >
                      <Icon>
                        <FaConciergeBell />
                      </Icon>
                      Concierge
                    </SubNavLink>
                    <SubHeading>
                      Provide the helpful and accurate replies customers need in
                      seconds
                    </SubHeading>
		    */}
                    <Label tw=" lg:!mt-4">EMAIL</Label>
                    <SubNavLink
                      onClick={() => (window.location.href = "/email")}
                    >
                      <Icon>
                        <MdEmail />
                      </Icon>
                      Email
                    </SubNavLink>
                    <SubHeading>
                      Boost revenue and save time with personalized emails that
                      convert
                    </SubHeading>
                    {/* <Label tw=" lg:!mt-4">VOICE</Label>
                    <SubNavLink
                      onClick={() => (window.location.href = "/voice-sms")}
                    >
                      <Icon>
                        <BiSolidUserVoice />
                      </Icon>
                      Voice SMS
                    </SubNavLink>
                    <SubHeading>
                      Connect effectively through personalized voice messages
                      that captivate..
                       and engage, fostering loyalty and driving
                      revenue.
                    </SubHeading> */}
                  </ul>
                </div>
              </SubMenuColumn>

              <SubMenuColumn>
                <div>
                  <Label>VOICE</Label>

                  <ul>
                    <SubNavLink
                      onClick={() => (window.location.href = "/voice-sms")}
                    >
                      <Icon>
                        <BiSolidUserVoice />
                      </Icon>
                      Voice SMS
                    </SubNavLink>
                    <SubHeading>
                      Connect effectively through personalized voice messages
                      that captivate and engage, fostering loyalty and driving
                      revenue.
                    </SubHeading>
                    <Label tw=" lg:!mt-4">LIST MANAGEMENT</Label>
                    <SubNavLink
                      onClick={() => (window.location.href = "/growth")}
                    >
                      <Icon>
                        <AiFillSignal />
                      </Icon>
                      Growth
                    </SubNavLink>
                    <SubHeading>
                      Boost engagement and revenue with a target audience ready
                      for your updates.
                    </SubHeading>
                    {/* <SubNavLink
                      onClick={() =>
                        (window.location.href =
                          "/audience-segmentation-manager")
                      }
                    >
                      <Icon>
                        <MdManageAccounts />
                      </Icon>
                      Audience Manager
                    </SubNavLink>
                    <SubHeading>
                      Reach the right audiences with precised targeting and
                      dynamic segmentation
                    </SubHeading> */}
                    {/* <SubNavLink
                      onClick={() => (window.location.href = "/integration")}
                    >
                      <Icon>
                        <MdIntegrationInstructions />
                      </Icon>
                      Intergrations
                    </SubNavLink>
                    <SubHeading>
                      Connect seamlessly with flexible integrations that drive
                      growth
                    </SubHeading> */}
                    {/* <SubNavLink
                      onClick={() => (window.location.href = "/complience")}
                    >
                      <Icon>
                        {" "}
                        <HiMiniArchiveBoxArrowDown />
                      </Icon>
                      Compliance
                    </SubNavLink>
                    <SubHeading>
                      Run your program with tools designed to protect you from
                      potential risk
                    </SubHeading> */}

                    {/* <SubNavLink
                  onClick={() => (window.location.href = "/messaging")}
                >
                  <Icon>
                    <AiFillPieChart />
                  </Icon>
                  Analytics
                </SubNavLink>
                <SubHeading>
                  Make data-driven decisions with detailed, customizable reports
                </SubHeading>
                <SubNavLink
                  onClick={() => (window.location.href = "/messaging")}
                >
                  <Icon>
                    <FaWandMagicSparkles />
                  </Icon>
                  Attentive AI™
                </SubNavLink>
                <SubHeading>
                  Create and send top performing campaigns in a fraction of the
                  time
                </SubHeading> */}
                  </ul>
                </div>
              </SubMenuColumn>

              <SubMenuColumn>
                <div>
                  {/* <Labels></Labels> */}
                  <ul>
                    {/* <SubNavLink
                      onClick={() => (window.location.href = "/integration")}
                    >
                      <Icon>
                        <MdIntegrationInstructions />
                      </Icon>
                      Intergrations
                    </SubNavLink>
                    <SubHeading>
                      Connect seamlessly with flexible integrations that drive
                      growth
                    </SubHeading> */}
                    {/* <SubNavLink
                      onClick={() => (window.location.href = "/complience")}
                    >
                      <Icon>
                        {" "}
                        <HiMiniArchiveBoxArrowDown />
                      </Icon>
                      Compliance
                    </SubNavLink>
                    <SubHeading>
                      Run your program with tools designed to protect you from
                      potential risk
                    </SubHeading> */}
                    <SubNavLink
                      onClick={() =>
                        (window.location.href =
                          "/audience-segmentation-manager")
                      }
                    >
                      <Icon>
                        <MdManageAccounts />
                      </Icon>
                      Audience Manager
                    </SubNavLink>
                    <SubHeading>
                      Reach the right audience with your message, maximizing the
                      impact of your campaigns.
                    </SubHeading>
                    <SubNavLink
                      onClick={() => (window.location.href = "/integration")}
                    >
                      <Icon>
                        <MdIntegrationInstructions />
                      </Icon>
                      Integration
                    </SubNavLink>
                    <SubHeading>
                      Unleash the full potential of your platform with flexible
                      integrations that drives growth.
                    </SubHeading>
                    <SubNavLink
                      onClick={() =>
                        (window.location.href = "/client-strategy")
                      }
                    >
                      <Icon>
                        <RiShieldUserFill />
                      </Icon>
                      Client Strategy
                    </SubNavLink>
                    <SubHeading>
                      Get personalized guidance to set your program up for
                      success from day one
                    </SubHeading>
                    {/* <SubNavLink
                  onClick={() => (window.location.href = "/messaging")}
                >
                  <Icon>
                    <TbWorldSearch />
                  </Icon>
                  Global Search
                </SubNavLink>
                <SubHeading>
                  Deliver the right messages to the right customers across 20+
                  countries
                </SubHeading> */}
                  </ul>
                </div>
              </SubMenuColumn>
              <SubMenuColumns tw="hidden lg:block ">
                <Card onClick={() => (window.location.href = "/contact")}>
                  <PaddingCard>
                    {/* <Subheading tw="mb-2">Blog</Subheading> */}
                    <img src={Images.Blog} tw=" w-144" />
                    <Heading tw="my-4">
                      Engage Audience, Boost Conversions
                    </Heading>
                    {/* <Link>Learn More</Link> */}
                  </PaddingCard>
                </Card>
              </SubMenuColumns>
            </SubMenu>
          </WideDiv>
          {/* </MaxWidth> */}
        </>
      )}
      {/* </MaxWidth> */}
    </div>
  );
};

const Card = tw.div`bg-gray-100`;
const ResoCard = tw.div`bg-[#FFF382] rounded-2xl h-80`;
const PaddingCard = tw.div`px-4 py-2`;
const MaxWidth = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;
const WideDiv = tw.div`
  absolute  left-0 right-0  mx-auto lg:mt-8 mt-12
    z-10 py-4 px-6 bg-white border-b shadow-lg h-full lg:h-auto
  flex lg:space-x-10   w-full 
`;

const SubMenu = tw.div`
  max-w-screen-xl mx-auto 
    z-10  bg-transparent   
  flex lg:space-x-10   w-full flex-col lg:flex-row overflow-y-auto
`;
// const SubMenu = tw.div`
//   absolute  left-0 right-0  max-w-screen-xl mx-auto mt-12
//     z-10 py-4 px-6 bg-transparent   h-full lg:h-128
//   flex lg:space-x-10   w-full flex-col lg:flex-row overflow-y-auto
// `;
const SubMenus = tw.div`
   max-w-screen-xl mx-auto mt-12
    z-10    h-full lg:h-80
  flex lg:space-x-10   w-full flex-col lg:flex-row overflow-y-auto
`;
// const SubMenu = tw.div`
//   absolute left-0 right-0 mt-12  z-10 py-4 px-6 bg-gray-100   h-full lg:h-128
//   flex lg:space-x-10 w-full flex-col lg:flex-row overflow-y-auto
//   max-w-6xl mx-auto w-full
// `;

const Label = tw.h3`text-[0.70rem] bg-primary-500  text-white my-2  text-center rounded-full  px-2 inline-block leading-loose font-thin items-center`;
const Labels = tw.div`text-sm lg:mb-10 `;
// const SubMenuColumn = tw.div`flex-1   `;
// const SubMenuColumns = tw.div`flex-1 `;
const SubMenuColumn = tw.div`grid grid-cols-1 lg:w-1/3 `;
const SubMenuColumns = tw.div`grid grid-cols-2  `;
// const SubHeading = tw.p`text-sm text-gray-600 text-left ml-6   mb-2`;
const SubHeading = tw.p`mt-1 ml-6 text-[0.65rem] text-secondary-100 text-left leading-loose font-thin`;
const Icon = tw.div`mt-1 mr-2`;
const Heading = tw.div`text-lg text-black z-10 mt-3`;
