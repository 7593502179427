const Images = {
  Logo: "./images/logo.png",
  TXT: "./images/txtc.png",
  PartnersLogos: "./images/logos.png",
  LogoWhite: "./images/logo_white.png",
  MultiPurpose: "./images/mulit-purpose.png",
  OmniChannel: "./images/omniChannel.png",
  QuickEasy: "./images/quickEasy.png",
  LandingImage: "./images/landing_image.svg",
  SMSLove: "./images/smslove.svg",
  Cms: "./images/cms.png",
  LoginSideImage: "./images/login-side.jpg",
  SignUpSideImage: "./images/sign-up-side.jpg",
  Client: "./images/client.jpg",
  Client2: "./images/client2.webp",
  TestimonialLogo1: "./images/TangPalace.png",
  TestimonialLogo2: "./images/RevivGhana.png",
  TestimonialLogo3: "./images/cimaf.png",
  TestimonialLogo4: "./images/fidelio.png",
  Fb: "./images/fb.png",
  Gmail: "./images/gmail.png",
  HomePage: "./images/homepage.png",
  Message: "./images/Message.png",
  Mass: "./images/Mass.png",
  Great_Message: "./images/Great_Message.jpg",
  Client: " ./images/client.png ",
  Attentive: "./images/attentive.svg",
  AttentiveEmail: "./images/attentiveEmailsAtt.png",
  Attentive2: "./images/attentive2.svg",
  Attentive3: "./images/attentive3.png",
  Michales: "./images/michales.svg",
  Email1: "./images/email1.png",
  Email2: "./images/email2.svg",
  Email3: "./images/email3.png",
  Msg: "./images/promotional_campaigns.png",
  Msg1: "./images/customer_reminders.png",
  Msg2: "./images/loyalty_programs.png",
  Msg3: "./images/sms_how_to.png",
  VoiceMsg1: "./images/voice_experience_the_Power.png",
  VoiceMsg2: "./images/voice_how_to.png",
  AttEmail: "./images/AttentiveEmail.png",
  Lead: "./images/leadnurturing.jpg",
  AttEmail3: "./images/howemail.svg",
  // AttEmail3: "./images/AttentiveEmail3.png",
  Nestle1: "./images/Nestle1.png",
  SmsDel: "./images/smsdel.png",
  SmsDel1: "./images/smsdel1.png",
  SmsDel2: "./images/smsdel2.png",
  SmsDel3: "./images/smsdel3.png",
  SmsDel4: "./images/smsdel4.png",
  Con: "./images/con.png",
  Con1: "./images/con1.png",
  Con2: "./images/con2.png",
  Con3: "./images/con3.png",
  Con4: "./images/conc4.png",
  Grow: "./images/Grow.png",
  Grow1: "./images/Grow1.png",
  Grow2: "./images/grow2.png",
  Growth: "./images/growth.png",
  Growth1: "./images/Growth1.png",
  Audience1: "./images/audience1.png",
  Audience3: "./images/audience3.png",
  Audience4: "./images/audience4.png",
  Audience5: "./images/audience5.png",
  Audience6: "./images/audience6.png",
  Audience: "./images/audience.png",
  Integration: "./images/integration.png",
  Integration1: "./images/integration1.png",
  Integration2: "./images/integration2.png",
  Integration3: "./images/integration3.png",
  Integration4: "./images/integration4.png",
  Integration5: "./images/integration5.png",
  Integration6: "./images/integration6.png",
  Clients: "./images/clients.png",
  Client1: "./images/client1.png",
  Client2: "./images/client2.png",
  Client3: "./images/client3.png",
  Client4: "./images/client4.png",
  Client5: "./images/client5.png",
  Client6: "./images/client6.png",
  Comp: "./images/complience.png",
  Comp1: "./images/compliance1.png",
  Comp2: "./images/compliance2.png",
  Blog: "./images/Blog.png",
  Resources: "./images/resources.png",
  Reso: "./images/reso.png",
  Hub: "./images/hub.svg",
  Why: "./images/why.svg",
  Guide: "./images/guide.png",
  Guide1: "./images/guide1.png",
  Guide2: "./images/guide2.png",
  Guide3: "./images/guide3.png",
  Guide4: "./images/guide4.png",
  Guide5: "./images/guide5.png",
  Guide6: "./images/guide6.png",
  Guide7: "./images/guide7.png",
  GuideSenderIdThumb: "./images/GuideSenderIDThumb.png",
  GuideSenderIdMain: "./images/guide_senderId_main.png",
  GuideBulkSmsThumb: "./images/guide_bulk_sms_thumb.png",
  GuideBulkSmsMain: "./images/guide _bulk_sms_main.png",
  GuideVoiceSmsThumb: "./images/guide_voice_sms_thumb.png",
  GuideVoiceSmsMain: "./images/guide_voice_sms_main.png",
  GuideEmailThumb: "./images/guide_email_thumb.png",
  GuideEmailMain: "./images/guide_email_main.png",
  CaseStudy1_thumb: "./images/case_study1_thumb.png",
  Case: "./images/case.png",
  Case1: "./images/case1.png",
  Case2: "./images/case2.png",
  Case3: "./images/case3.png",
  Case4: "./images/case4.png",
  Case5: "./images/case5.png",
  Case6: "./images/case6.png",
  Clairs: "./images/clairs.png",
  Apl: "./images/apl.png",
  Guess: "./images/guess.png",
  Bar: "./images/bar.png",
  Nestle: "./images/nestle.png",
  SMS: "./images/SMS.jpg",
  EMAil: "./images/Email.jpg",
  VOICE: "./images/Voice.jpg",
  Help: "./images/help.png",
  Help1: "./images/help1.png",
  Newsletter: "./images/Newsletter.png",
  Customer: "./images/Customer.png",
  Ecommerce: "./images/Ecommerce.png",
  About: "./images/About.png",
};

export default Images;
