import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import Images from "Images.js";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/4`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center `;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded`,
]);
const Category = tw.div`mt-4 text-secondary-100 font-bold text-sm`;
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg `;
const Link = tw.a`inline-block mt-2 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

const BtnContainer = tw.div`flex justify-center`;
const Btn = tw.a` mt-12 text-sm   cursor-pointer text-primary-500 rounded-md hover:bg-primary-500 hover:text-white transition duration-300 border border-primary-500 py-2 px-4 hover:border-primary-500`;
export default () => {
  const blogPostsPerPage = 3; // Number of blog posts to show per page
  const [displayedPosts, setDisplayedPosts] = useState(blogPostsPerPage); // State to manage displayed posts

  const handleViewMore = () => {
    setDisplayedPosts((prev) => prev + blogPostsPerPage);
  };
  const blogPosts = [
    {
      imageSrc: Images.GuideSenderIdThumb,
      category: "SENDERID GUIDE",
      title: "How to Request Sender ID on TXTConnect for Bulk SMS",
      url: "/guide/sender-id",
    },
    {
      imageSrc: Images.GuideBulkSmsThumb,
      category: "BULK SMS GUIDE",
      title: "Step-by-Step Guide: How to Send Bulk SMS with TXT...",
      url: "/guide/bulk-sms",
    },
    {
      imageSrc: Images.GuideVoiceSmsThumb,
      category: "VOICE SMS GUIDE",
      title: "Here is a step-by-step guide on how to send a voice...",
      url: "/guide/voice-sms",
    },
    {
      imageSrc: Images.GuideEmailThumb,
      category: "EMAIL MARKETING GUIDE",
      title: "Here is a step-by-step guide on how to use email...",
      url: "/guide/email-marketing",
    },

    // {
    //   imageSrc:
    //   Images.Guide5,
    //   category: "Event Tips",
    //   title: "Level Up Your BFCM E-Commerce Marketing Strategy With SMS",
    //   url: "/contact"
    // },
    // {
    //   imageSrc:
    //     Images.Guide6,
    //   category: "Reviews",
    //   title: "Marketing Mix: 5 Loyalty Plays to Grow Revenue",
    //   url:"/contact"
    // },
    // {
    //   imageSrc:
    //   Images.Guide7,
    //   category: "Discover",
    //   title: "The Marketer’s Guide to Using AI",
    //   url: "/contact"
    // },
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Explore Our Guides</HeadingTitle>
          <HeadingDescription>
            Empower your bulk messaging expertise with our comprehensive library
            of guides.
          </HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Category>{post.category}</Category>
                <Title>{post.title}</Title>
                <Link href={post.url}>Read Post</Link>
              </Card>
            </Column>
          ))}
          {/* {blogPosts.map((post, index) => ( */}
          {/* {blogPosts.slice(0, displayedPosts).map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
               
                <Title>{post.title}</Title>
                <Link href={post.url}>Read Now</Link>
              </Card>
            </Column>
          ))} */}
        </ThreeColumn>
        {/* {displayedPosts < blogPosts.length && (
        <BtnContainer>
        <Btn onClick={handleViewMore}>View More</Btn>
        </BtnContainer>)} */}
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
