import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
import Images from "Images.js";
import Videos from "Videos.js";
import Config from "Config.js";
const Container = tw.div`relative md:mt-6`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 lg:py-10`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative  flex-1 flex flex-col justify-center lg:self-start lg:justify-end mt-12 lg:mt-0`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base  text-gray-700`;

const Actions = styled.div`
  // ${tw`relative max-w-md flex justify-center items-center lg:justify-start text-center mx-auto lg:mx-0`}
  ${tw`w-full bg-primary-500 text-gray-100 font-bold  mr-2 my-4 sm:my-2 rounded-full py-4  flex items-center justify-center items-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  // button {
  //   ${tw`w-full bg-primary-500 text-gray-100 font-bold ml-2 mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center items-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  // }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center`;
const VideoContainer = tw.div`relative aspect-auto h-[11rem] lg:h-72 border w-full lg:w-[75%] lg:self-end`;
// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
const Iframe = tw.iframe`w-full h-full`;
export default ({ roundedHeaderButton, user, primaryLinkUrl = "/login" }) => {
  return (
    <>
      {/* <Header roundedHeaderButton={roundedHeaderButton} user={user} /> */}
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Engage Your Audience At Scale With <br />
              TXTConnect
              {/* <span tw="text-primary-500">TXTConnect</span> */}
            </Heading>
            <Paragraph>
              Using{" "}
              <a
                href={Config.messagingUrl}
                target="_blank"
                style={{ fontWeight: "bold" }}
                className="blue-on-hover"
              >
                Bulk SMS
              </a>{" "}
              /{" "}
              <a
                href={Config.emailUrl}
                target="_blank"
                style={{ fontWeight: "bold" }}
                className="blue-on-hover"
              >
                Email Marketing
              </a>{" "}
              /{" "}
              <a
                href={Config.voiceUrl}
                target="_blank"
                style={{ fontWeight: "bold" }}
                className="blue-on-hover"
              >
                Voice SMS
              </a>{" "}
              & More!
            </Paragraph>
            <Paragraph>
              TXTConnect provides specialized communication tools for{" "}
              <span tw="text-gray-900 font-medium">
                businesses of all sizes{" "}
              </span>{" "}
              that help simplify relationship building and drive revenue
            </Paragraph>
            <Actions>
              <a href={primaryLinkUrl}>
                <button>Get Started</button>
              </a>
            </Actions>
          </LeftColumn>
          <RightColumn>
            {/* <IllustrationContainer> */}
            {/* <video
                tw="min-w-0 w-full max-w-lg"
                src={Videos.video}
                
                // alt="Design Illustration"
                controls
              /> */}
            <VideoContainer>
              <Iframe
                src="https://www.youtube.com/embed/46ZjlVwxvfI"
                title="Explainer Video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></Iframe>
            </VideoContainer>
            {/* </IllustrationContainer> */}
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
