import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { SectionHeading } from "components/misc/Headings.js";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";

import { BiTimer, BiMessageSquareCheck } from "react-icons/bi";
import { HiOutlineArrowsExpand } from "react-icons/hi";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full leading-none`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-4 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }
  .subtitle {
    ${tw`mt-2 font-bold text-lg leading-none `}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  const cards = [
    {
      imageSrc: ShieldIconImage,
      icon: <HiOutlineArrowsExpand tw="w-8 h-8 text-primary-500" />,
      title: "Innovative Platform",
      subtitle: "Advanced automation for campaigns and journeys",
      description:
        "Attentive Email’s AI and automation capabilities are designed to help you orchestrate and maximize your SMS and email marketing programs. Optimize your customer touchpoints on one easy-to-use platform.",
    },
    {
      imageSrc: SupportIconImage,
      icon: <BiMessageSquareCheck tw="w-8 h-8 text-primary-500" />,
      title: "Support",
      subtitle: "Strategic guidance from launch to optimization",
      description:
        "From complete migration in days rather than months to ongoing strategic guidance, our industry-leading team of experts work with you to build an email marketing program that inspires action and increases revenue.",
    },
    {
      icon: <BiTimer tw="w-8 h-8 text-primary-500" />,
      title: "Support",
      description:
        "From complete migration in days rather than months to ongoing strategic guidance, our industry-leading team of experts work with you to build an email marketing program that inspires action and increases revenue.",
    },
  ];

  return (
    <Container id="features">
      <ThreeColumnContainer>
        <Heading>
          The highly reliable platform for <br /> global brands
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer ">{card.icon}</span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>

                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
