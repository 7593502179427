import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import HeaderBase, {
  NavLinks,
  NavLink,
  PrimaryLink,
} from "components/headers/light.js";
import Header from "../headers/light.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Images from "Images";
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = tw(
  SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
// const Heading = tw(
//   SectionHeading
// )`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-none `;
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-xl font-medium leading-relaxed text-secondary-800`;

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`,
]);
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base  text-gray-700`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`;
const Input = tw.input`border-2 px-5 py-3 w-full rounded-full focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`;

const Card = tw.div`  border border-gray-100 bg-white hover:bg-gray-100  px-8 py-4 lg:mx-24 shadow-md cursor-pointer`;
const CardHeading = tw.h2`md:text-2xl text-xl font-bold  `;
const CardSubHeading = tw.h2` text-base font-semibold mb-1 `;
const CardDescription = tw.p`text-sm text-gray-700 `;

export default ({
  subheading = "GROWTH",
  heading = <>Convert visitors into subscribers</>,
  description = "Search our knowledge base for answers to common questions",

  primaryButtonUrl = "/contact",
  formAction = "#",
  formMethod = "get",
  buttonRounded = true,

  showDecoratorBlob = false,
  textOnLeft = true,
  features = null,

  roundedHeaderButton,
  user,
}) => {
  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: "Professionalism",
      description:
        "We have the best professional marketing people across the globe just to work with you.",
      iconContainerCss: tw`bg-teal-300 text-teal-800`,
    },
    {
      Icon: MoneyIcon,
      title: "Affordable",
      description:
        "We promise to offer you the best rate we can - at par with the industry standard.",
      iconContainerCss: tw`bg-red-300 text-red-800`,
    },
  ];

  const buttonRoundedCss = buttonRounded && tw`rounded-full`;

  if (!features) features = defaultFeatures;

  return (
    <>
      {/* <Header roundedHeaderButton={roundedHeaderButton} user={user} /> */}
      <Container>
        <TwoColumn>
          <ImageColumn>
            {/* <Image src={Images.Help} />
            {showDecoratorBlob && <DecoratorBlob />} */}
            <Card>
              <CardHeading>How To Guide</CardHeading>
            </Card>
            {/* <hr /> */}
            <Card onClick={() => (window.location.href = "/guide/sender-id")}>
              <CardSubHeading>
                How to Request Sender Id on TXTConnect
              </CardSubHeading>
              <CardDescription>
                Request Sender Id on TXTConnect for Bulk SMS
              </CardDescription>
            </Card>
            <Card onClick={() => (window.location.href = "/guide/bulk-sms")}>
              <CardSubHeading>How to Send SMS with TXTConnect</CardSubHeading>
              <CardDescription>
                Sending SMS, Bulk SMS and Scheduling SMS
              </CardDescription>
            </Card>
            {/* <hr /> */}
            <Card onClick={() => (window.location.href = "/guide/voice-sms")}>
              <CardSubHeading>
                How to Send Voice Messages with TXTConnect
              </CardSubHeading>
              <CardDescription>
                Sending Voice, Bulk Voice and Scheduling Voice Messages
              </CardDescription>
            </Card>
            {/* <hr /> */}
            <Card
              onClick={() => (window.location.href = "/guide/email-marketing")}
            >
              <CardSubHeading>
                How to Send Email Messages with TXTConnect
              </CardSubHeading>
              <CardDescription>
                Sending Email, Bulk Email and Scheduling Email Messages
              </CardDescription>
            </Card>
            {/* <hr /> */}
            {/* <Card>
              <CardSubHeading>Adjust sign-up unit display rules</CardSubHeading>
              <CardDescription>
                Control when and where your sign-up units appear
              </CardDescription>
            </Card> */}
            {/* <hr /> */}
            {/* <Card>
              <CardSubHeading>Create a new journey</CardSubHeading>
              <CardDescription>
                Use templates to send triggered messages
              </CardDescription>
            </Card> */}
            {/* <hr /> */}
            {/* <Card>
              <CardSubHeading>Optimize images in MMS messages</CardSubHeading>
              <CardDescription>
                Increase engagement with your MMS messages
              </CardDescription>
             
            </Card> */}
          </ImageColumn>

          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <Subheading>HELP CENTER</Subheading>
              <Heading>
                How Can We Help
                <br /> You Today?
              </Heading>
              <Paragraph>{description}</Paragraph>

              {/* <Form action={formAction} method={formMethod}>
                <Input type="search" name="search" placeholder="Search" />
               
              </Form> */}
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
