import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import ContactUsForm from "components/revenue/ContactUsForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import styled from "styled-components";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Config from "Config";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;
const MaxWidth = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;

export default () => {
  const [user, setUser] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [formVal, setFormVal] = useState({
    email: "",
    name: "",
    subject: "",
    message: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setFormVal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      setisLoading(true);
      console.log("submit", formVal);
      const res = await axios.post(`${Config.nodeApiUrl}/sendMail`, formVal, {
        headers: {
          for: "web",
        },
      });
      console.log(res);
      toast.success(res?.data?.msg || "Success");
      setisLoading(false);

      setFormVal({
        email: "",
        name: "",
        subject: "",
        message: "",
      });
    } catch (err) {
      toast.error("Something went wrong!");

      setisLoading(false);
    }
  };
  return (
    <>
      <ContactUsForm
        formVal={formVal}
        changeHandler={changeHandler}
        submitHandler={submitHandler}
        isLoading={isLoading}
      />
    </>
  );
};
