import React from "react";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
const Container = tw.div`flex justify-center flex-col md:flex-row sm:px-8 mx-auto max-w-screen-xl pb-20 md:pb-24 lg:space-x-8`;
const FirstSection = tw.div`md:w-3/12  p-4 text-left order-last md:order-first`;
const SecondSection = tw.div`md:w-9/12 p-4 order-first md:order-last`;
const Heading = tw.h1`font-bold text-xl md:text-2xl lg:text-3xl xl:text-3xl  md:mb-2  leading-tight`;
const OthHeading = tw.h1`font-bold text-xl md:text-2xl lg:text-3xl xl:text-3xl  my-2 md:mt-16 md:mb-2  leading-tight`;
const Subheading = tw(SubheadingBase)`text-center md:text-left md:mb-8 mb-4 `;
const Paragraph = tw.p`mb-2 lg:mb-4 text-base  text-gray-700`;
const ComHeading = tw.h1`font-semibold text-lg md:text-xl lg:text-xl xl:text-xl text-gray-900 my-2 lg:my-1 leading-tight`;
const OrderedList = tw.ol`mb-1 lg:mb-2 lg:mt-4 mt-2 text-sm md:text-xl font-semibold  list-decimal pl-4 text-black`;
const ComParagraph = tw.p`mb-2 lg:mb-4 text-sm  text-gray-700`;
const List = tw.li` text-sm ml-1 text-gray-700`;
const ListItem = tw.li` text-base  text-black`;

// const OrderedList = tw.ol`list-decimal pl-4 mb-4`;

const UnorderedList = tw.ul`list-disc pl-4 mb-4`;
const CompanyDetails = () => {
  return (
    <Container>
      <FirstSection>
        {/* Company Details */}
        {/* <Heading>SMS Strategy</Heading>
        <Paragraph>
          Nestlé Ghana Limited started business in Ghana in 1957 under the
          trading name of Nestlé Products (Gh) Limited with the importation of
          Nestlé products such as milk and chocolates. In 1968, it was
          incorporated as Food Specialties (Gh) Limited to manufacture and
          market locally well known Nestlé brands.
        </Paragraph> */}

        <ComHeading>Posted in</ComHeading>
        <ComParagraph>SMS Strategy</ComParagraph>
        <ComHeading>Published on</ComHeading>
        <ComParagraph>2023</ComParagraph>
        <ComHeading>Written by</ComHeading>
        <ComParagraph>TXTConnect Team</ComParagraph>

        {/* Add more details about the company */}
      </FirstSection>

      <SecondSection>
        <Heading>
          Here is a step-by-step guide on how to use email messages on
          TXTConnect
        </Heading>

        <OrderedList>
          <ListItem>
            Access the Email Messaging Feature
            <UnorderedList>
              <List>
                Log in to your TXTConnect account using your email address and
                password.
              </List>
              <List>
                Navigate to the "Email" tab located on your dashboard and click
                on it
              </List>
              <List>
                Select more on the tasks bar and click on email management to
                create your email ID
              </List>
              <List>
                Request email ID, provide your preferred email ID and submit,
                once it is approved you can continue to send your email
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Compose the Email
            <UnorderedList>
              <List>Click on the "Send Email" button.</List>
              <List>Enter your email address in the designated field.</List>
              <List>
                Enter the recipient's email address in the "To" field.
              </List>
              <List>
                Provide a subject line that clearly conveys the message's
                content.
              </List>
              <List>Compose your email message in the text box provided.</List>
              <List>
                Attach any relevant files by clicking on the "Attach Files"
                button and selecting the desired files.
              </List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Send the Email
            <UnorderedList>
              <List>
                Review the email to ensure it is accurate and complete.
              </List>
              <List>
                Click on the "Send" button to deliver the email to the
                recipient.
              </List>
            </UnorderedList>
          </ListItem>
        </OrderedList>
        <OthHeading>
          Here is a step-by-step guide on how to send a bulk email message on
          TXTConnect
        </OthHeading>

        <OrderedList>
          <ListItem>
            Prepare the Email Content
            <UnorderedList>
              <List>
                Compose the email message you want to send to multiple
                recipients.
              </List>
              <List>Save the email message</List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Create a Contact Group
            <UnorderedList>
              <List>Go to the dashboard and select the "Email" tab.</List>
              <List>
                Click on the "Contacts" option and choose "Contact Group."
              </List>
              <List>
                Click on "Download Contact Templates" to obtain the contact
                template file.
              </List>
              <List>Open the template file in a spreadsheet program.</List>
              <List>
                Enter the contact details of your recipients, including their
                phone numbers, usernames, companies, first names, and last
                names.
              </List>
              <List>Save the updated contact template file.</List>
            </UnorderedList>
          </ListItem>

          <ListItem>
            Upload the Contact Group and Email Content
            <UnorderedList>
              <List>
                Go back to the TXTConnect dashboard and select the "Email" tab.
              </List>
              <List>
                Click on "Upload Contacts" and choose "Add Contact Group."
              </List>
              <List>Provide a name for the contact group.</List>
              <List>Upload the updated contact template file you created.</List>
              <List>
                Click on the "Add" button to import the contact group.
              </List>
            </UnorderedList>
          </ListItem>
        </OrderedList>
        <OthHeading>
          Here is a step-by-step guide on how to send a schedule email message
          on TXTConnect
        </OthHeading>

        <OrderedList>
          <ListItem>
            Schedule or Send the Bulk Email
            <UnorderedList>
              <List>Go back to the dashboard and select the "Email" tab.</List>
              <List>Click on "Send Email."</List>
              <List>Choose "Bulk Email" as the message type.</List>
              <List>Enter your email address in the designated field.</List>
              <List>
                Select the contact group you created from the "Contact Group"
                dropdown menu.
              </List>
              <List>Provide a campaign name for identification purposes.</List>
              <List>Enter a clear subject line for the email message.</List>
              <List>
                Compose the email message that will be sent to all recipients in
                the group.
              </List>
              <List>
                Attach any relevant files by clicking on the "Attach Files"
                button.
              </List>
              <List>
                If you want to schedule the email to be sent at a specific time,
                click on the "Schedule Email" checkbox and enter the desired
                date and time.
              </List>
              <List>
                Click on the "Send" button to deliver the bulk email to all
                recipients in the group.
              </List>
            </UnorderedList>
          </ListItem>
        </OrderedList>
      </SecondSection>
    </Container>
  );
};

export default CompanyDetails;
