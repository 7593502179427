function NewRevenueCalculator() {
  return (
    <div tw="flex px-32 pb-24 pt-24">
      <div tw="w-1/2 py-12 ">
        <div tw="flex justify-between">
          <div>
            <h1 tw="text-2xl font-sans font-semibold pt-8 px-6">
              Total Monthly Website Traffic
            </h1>
            <h1 tw="font-light text-lg px-6 pt-2">What’s this?</h1>
          </div>
          <input
            type="text"
            placeholder="500,000"
            tw="mt-11 mr-4 border-2 text-lg border-black p-4 w-44"
          />
        </div>
        <div tw="flex justify-between">
          <div>
            <h1 tw="text-2xl font-sans font-semibold pt-12 px-6">
              Average Order Value
            </h1>
            <h1 tw="font-light text-lg px-6 pt-2">What’s this?</h1>
          </div>
          <input
            type="text"
            placeholder="$110"
            tw="mt-14 mr-4 border-2 text-lg border-black p-4 w-44"
          />
        </div>
        <div tw="flex justify-between">
          <div>
            <h1 tw="text-2xl font-sans font-semibold pt-12 px-6">
              Existing SMS List
            </h1>
            <h1 tw="font-light text-lg px-6 pt-2">What’s this?</h1>
          </div>
          <input
            type="text"
            placeholder="0"
            tw="mt-14 mr-4 border-2 text-lg border-black p-4 w-44"
          />
        </div>
        <div tw="flex justify-between">
          <div>
            <h1 tw="text-2xl font-sans font-semibold pt-12 px-6">
              Conversion Rate
            </h1>
            <h1 tw="font-light text-lg px-6 pt-2">What’s this?</h1>
          </div>
          <input
            type="text"
            placeholder="1.5                   %"
            tw="mt-14 mr-4 border-2 text-lg border-black p-4 w-44"
          />
        </div>
        <div tw="flex justify-between">
          <div>
            <select tw="font-sans pt-20 px-6 ">
              <option>United States</option>
              <option>India</option>
              <option>England</option>
              <option>Australia</option>
            </select>
          </div>
          <button
            type="button"
            tw="bg-black text-white mt-12 w-52 mr-3 rounded-md"
          >
            Reset Inputs
          </button>
        </div>
      </div>
      <div tw="w-1/2 bg-pink-50">
        <div>
          <h1 tw="text-3xl font-sans font-semibold pt-16 px-10">attentive</h1>
        </div>
        <div tw="flex justify-between px-10 py-14 mr-12">
          <div>
            <h1 tw="text-3xl  font-bold">Subscribers</h1>
            <h1 tw="text-xl font-sans">
              Incremental subscribers <br />
              in first 12 months
            </h1>
          </div>
          <div>
            <h1 tw="text-3xl  font-bold">Revenue</h1>
            <h1 tw="text-xl font-sans">
              Incremental revenue in <br /> first 12 months
            </h1>
          </div>
        </div>
        <div tw="flex justify-between py-6">
          <div>
            <p tw="text-3xl px-10 font-bold">-</p>
          </div>
          <div tw="mr-56">
            <p tw="text-3xl px-10 font-bold">-</p>
          </div>
        </div>
        <div tw="p-10">
          <button
            type="button"
            tw="border-2 border-black p-4 w-64 hover:bg-black hover:text-white rounded-md text-xl"
          >
            Copy Link to Share
          </button>
        </div>
      </div>
    </div>
  );
}
export default NewRevenueCalculator;
