import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import Images from "Images";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-2xl mx-auto py-20 sm:py-12 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center `;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-5/12 flex-shrink-0 h-80 md:h-96  bg-no-repeat bg-contain bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-12  lg:my-0`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-xl mx-4 sm:mx-8 md:mx-4 lg:mx-12`;

const Title = tw.h4`text-2xl sm:text-2xl lg:text-3xl font-bold text-gray-900 leading-tight`;
const Description = tw.li`mt-2 text-base font-medium  `;
const Link = tw.a`inline-block mt-4 text-sm   cursor-pointer text-primary-500 rounded-md hover:bg-primary-500 hover:text-white transition duration-300 border border-primary-500 py-2 px-4 hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: Images.Grow,
      subtitle: "Identify and engage more of your on-site browsers",
      title: "Convert Audience To Loyal Customers",
      list: "Create messages that resonate with your audience, emphasizing the unique benefits of your offerings.",
      list1:
        "Utilize instant messaging features for timely responses to inquiries, guiding potential customers through their decision-making process.",
      list2:
        "Drive conversions by sharing special promotions or exclusive deals through messaging, incentivizing visitors to take immediate action.",
      url: "/contact",
    },

    {
      imageSrc: Images.Grow1,
      subtitle: "Easily create personalized campaigns that convert",
      title: "Customize  Messages Effortlessly",
      list: "Tailor your messaging strategy with ease using our platform's user-friendly interface.",
      list1:
        "Easily adjust and modify your communication approach on the go, responding to changing needs with speed and simplicity.",
    },

    // {
    //   imageSrc: Images.Grow2,
    //   subtitle:
    //     "An industry-first, unified composer for email and SMS that makes orchestration and retargeting fast and easy",
    //   title: "Analyze performance and refine over time",
    //   list: "A/B test design elements, offers, and copy to continually improve performance",
    //   list1: "Track trends and see which sources are driving the most growth",
    //   list2:
    //     "Access detailed metrics and generate pre-built, customizable analytics reports",
    // },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>
            Create Impactful Experiences That <br /> Boost Conversions
          </HeadingTitle>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Details>
                <Title>{card.title}</Title>

                <Description>{card.list}</Description>
                <Description>{card.list1}</Description>
                {card.learn && <Description>{card.list2}</Description>}
                {card.learn && <Link href={card.url}>{card.learn}</Link>}
              </Details>
              <Image imageSrc={card.imageSrc} />
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
